import { VariableDto } from "./variable";

export interface DeviceValueMapShortDto {
    id: string;
    name: string;
}

export enum DashboardItemType {
    BLANK = 'BLANK',
    COUNTER = 'COUNTER',
    GROUP = 'GROUP',
    CHART = 'CHART'
}

export interface DashboardItem {
    id: string;
    name: string;
    type: DashboardItemType;
    data?: string;
    variable?: VariableDto;
}

export interface DashboardItemCreateDto {
    dashboardId: string;
    name: string;
    position: number;
}



export interface DashboardDto {
    id: string;
    name: string;
    items: DashboardItem[];
}
