import React, { Component } from 'react'
import { AuthApi } from '../../../api';
import { connect } from 'react-redux';
import { logout } from '../../../state/userReducer';

class Logout extends Component {
    componentDidMount() {
        console.log('logout componentDidMount', this.props);

        AuthApi.logout()
            .then((result) => {
                console.log('onlogout', result)
                this.props.dispatch(logout())
                localStorage.clear();
                this.props.history.push("/")
              
            }, (result) => {
                console.log('onlogout error', result)
                this.props.history.push("/")
            })
    }

    render() {
        return (
            <div></div>
        );
    }
}

const mapStateToProps = function (store) {
    return {

    };
};

export default connect(mapStateToProps)(Logout)