import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { PencilIcon, TrashIcon } from '@primer/octicons-react'

export default class ChamberListItem extends Component {

    render() {
        const { item, handlerDeleteItem } = this.props
        return (
            <tr className="nodeData">
                <th scope="row"><Link to={`/chamber/${item.id}`}  >{item.name}</Link></th>
                <td>{item.description}</td>
                <td>
                    <Link to={`/chamber/edit/${item.id}`}> <PencilIcon size='medium' /></Link>
                    <Link onClick={() => { handlerDeleteItem(item.id) }} to={"#"}> <TrashIcon size='medium' /></Link>
                </td>
            </tr>
        );
    }
}
