import { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
// import Navigation from './modules/navigation'
import { Dashboard, DashboardItemEdit } from './modules/dashboard'

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from './store';

import Login from './modules/user/pages/login';
import Logout from './modules/user/pages/logout';
import UserRegister from './modules/user/pages/userRegister';
import UserActivate from './modules/user/pages/userActivate';
import RequestNewPassword from './modules/user/pages/requestNewPassword';
import SetNewPassword from './modules/user/pages/settNewPassword';
import UserProfile from './modules/user/pages/userProfile';

import { DeviceList, DeviceCreate,  } from './modules/device/pages'
import { ChamberList, ChamberCreate, ChamberEdit, ChamberDetails } from './modules/chambers/pages'

import Accounting from './modules/accounting/accounting';
import StrainCreate from './modules/strain/pages/strainCreate';
import StrainEdit from './modules/strain/pages/strainEdit';
import StrainList from './modules/strain/pages/strainList';
import StrainDetails from './modules/strain/pages/strainDetails';
import ReportList from './modules/report/pages/reportList'
import { VariableList, VariableCreate, VariableEdit } from './modules/variable/pages'

import { Growing, Incubation } from './modules/accounting';
import { MiniDrawer } from './modules/drawer'
import { Help } from './modules/help';
import ResendActivateLink from './modules/user/pages/resendActivateLink';
import { QueryParamProvider } from 'use-query-params';
export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter >
          <QueryParamProvider ReactRouterRoute={Route}>
            {/* <Navigation /> */}
            <MiniDrawer>
              <Switch>
                {/* <Redirect exact from="/" to="login" /> */}

                <Route path="/help" component={Help} />
                <Route path="/logout" component={Logout} />
                <Route path="/login" component={Login} />
                <Route path="/register" component={UserRegister} />
                <Route path="/activate/:token" component={UserActivate} />
                <Route path="/requestNewPassword" component={RequestNewPassword} />
                <Route path="/setnewpassword/:linkId" component={SetNewPassword} />
                <Route path="/user/profile" component={UserProfile} />
                <Route path="/resendActivateLink" component={ResendActivateLink} />

                <Route path="/dashboard/item/edit/:id" component={DashboardItemEdit} />
                <Route path="/dashboard" component={Dashboard} />

                {/* <Route path="/device/create" component={DeviceCreate} /> */}
                <Route path="/device/create" component={DeviceCreate} />
                <Route path="/device/edit/:id" component={DeviceCreate} />
                <Route path="/device" component={DeviceList} />

                <Route path="/chamber/create" component={ChamberCreate} />
                <Route path="/chamber/edit/:chamberId" component={ChamberEdit} />
                <Route path="/chamber/:chamberId" component={ChamberDetails} />
                <Route path="/chamber" component={ChamberList} />


                <Route path="/accounting/growing" component={Growing} />
                <Route path="/accounting/incubation" component={Incubation} />
                <Route path="/accounting" component={Accounting} />

                <Route path="/strain/details/:strainId" component={StrainDetails} />
                <Route path="/strain/edit/:strainId" component={StrainEdit} />
                <Route path="/strain/copy/:strainId" component={StrainCreate} />
                <Route path="/strain/create/" component={StrainCreate} />
                <Route path="/strain" component={StrainList} />



                <Route path="/variable/create" component={VariableCreate} />
                <Route path="/variable/edit/:id" component={VariableEdit} />


                <Route path="/variable" component={VariableList} />




                <Route path="/report" component={ReportList} />

                <Route path="/" component={Login} />

              </Switch>
            </MiniDrawer>
          </QueryParamProvider>
        </BrowserRouter >
      </Provider>
    );
  }
}