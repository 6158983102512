import { combineReducers } from 'redux'
//import { TypedUseSelectorHook, useSelector as useReduxSelector, useDispatch as useReduxDispatch } from 'react-redux';
// Custom Reducers
/* import counterReducer from './counterReducer' */
import userReducer from './userReducer'

// Combine Reducers
const reducers = combineReducers({
 /*  counterState: counterReducer, */
  userState: userReducer
})




export default reducers