import React, { Component } from 'react'


export default class Help extends Component {
    render() {
        return (
            <div className='m-1'>
                <h1>Описание системы гриб контроль</h1>
                <p>Система создана для автоматизации учёта грибных блоков на производстве.</p>
                <p>Грибной блок проходит несколько стадий на производстве </p>
                <ol>
                    <li>создание блока из иннокулированного субстрата</li>
                    <li>установка блока в инкубатор </li>
                    <li>инкубация</li>
                    <li>перенос блока в камеру плодоношения </li>
                    <li>сбор гриба </li>
                    <li>утилизация</li>
                </ol>
                <h3>Возможности системы</h3>
                <ol>
                    <li>подключение устройств автоматического сбора показателей температура, влажность, просмотр текущих и прошлых значений по устройствам, виде графика.
                    Устройства могут собирать параметры камер инкубации, плодоношения, температуру варочной ёмкости.</li>
                    <li>вести справочник штаммов гриба,название штамма,  значения количество дней инкубации, количество дней плодоношения, описание штамма</li>
                    <li>вести учет камер(плодоношение, инкубации) а именно сколько в камере рядов и мест под блоки, это позволит точно отслеживать загрузку камеры, получать актуально количество блоков в камере и просматривать историю перемещений.</li>
                    <li>вести учет блоков а именно сколько сейчас блоков в каких камерах на каких местах и в каком статусе, автоматически подсказывать какие блоки пора переносить</li>
                    <li>раздел отчёт после того как блок будет утилизирован его можно посмотреть в разделе отчет, там агрегированная информация по блоку, штамм, время установки в инкубатор, продолжительность инкубации,комментарий инкубации, время установки в камеру плодоношения,комментарий плодоношения, время плодоношения, количество собранного гриба, время утилизации блока, причина утилизации, комментарий утилизации,
                    Информацию можно просматривать онлайн либо скачать и анализировать в Excel</li>
                </ol>
                <h3>Правила работы с веб системой:</h3>
                <p>Нужно зарегистрироваться в веб интерфейсе </p>
                <img alt='' src='help/register.png'></img>


                <p>Должно прийти письмо на почту, нужно перейти по ссылки чтобы активировать аккаунт,  если письмо не пришло проверить папку спам.</p>
                <p>После успешной активации можно войти в систему используя свой логин и пароль
                Должен отобразиться дашборд(приборная панель) на нем будут отображаться данные с датчиков, количество блоков в камерах.
                Далее необходимо создать камеры.</p>

                <img alt='' src='help/chamber.png'></img>
                <p>Для примера считаем что у нас две камеры камера инкубации в которой 5 рядов по 50 мест в каждом и камера плодоношения 5 рядов по 100 мест. Создание камеры инкубации</p>

                <img alt='' src='help/chamber_incubation_create_or_edit.png'></img>
                <p>Создание камеры плодоношения</p>
                <img alt='' src='help/chamber_growing_create_or_edit.png'></img>
                <p>После того как камера создана необходимо зайти в вновь созданную камеру. Нажать на кнопку скачать метки мест</p>
                <img alt='' src='help/chamber_details.png'></img>


                <p>Скачать файл и распечатать его, в нем находятся метки мест в камере,  их надо  разрезать  и  расположить в камере возле каждого места блока  в удобном для сканирования месте.
Так надо проделать и со второй камерой.</p>

                <p>После успешного создания камер необходимо занести штаммы используемые на производстве.</p>
                <p>Для примера будем использовать мицелий фирмы Futar R-80 </p>

                <img alt='' src='help/strain_create.png'></img>


                <p>Список созданных штаммов </p>

                <img alt='' src='help/strain_list.png'></img>
                <p>После создания камер на дашборд можно добавить блоки которые будут отображать актуальное количество блоков в них.</p>

                <h3>Правила работы с Терминалом</h3>

                <a href='https://play.google.com/store/apps/details?id=com.acumendev.gribcontrol.terminal&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img height='80' width='180' alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
                <p>Терминал представляет собой приложение устанавливаемое на устройства с операционной системой android 5.5 и выше.</p>
                <p>Основные функции </p>

                <ol>

                    <li>Экран входа используется логин пароль от веб интерфейса</li>
                    <li>Экран установки блоков на инкубацию</li>
                    <li>Экран установки блоков на плодоношение </li>
                    <li>Экран сбора гриба</li>
                    <li>Экран утилизации блоков</li>
                    <li>Экран просмотра информации о блоке</li>
                </ol>

                <h4>Порядок работы</h4>

                <p>В веб интерфейсе необходимо перейти в раздел учет и нажать кнопку скачать метки блоков распечатать (обычная или самоклеющаяся бумага) нарезать.</p>
                <p>Сделать грибные блоки наклеить на каждый метку блока чтобы она хорошо читалась без загибов.</p>
                <p>Разместить блоки в камере инкубации, на терминале нажать кнопку  Инкубация</p>
                <img alt='' src='help/terminal/login.png' height='500'></img><br/>
                <img alt='' src='help/terminal/main.png' height='500'></img><br/>
                <img alt='' src='help/terminal/incubation.png' height='500'></img>
                <p>Выбрать штамм если необходимо ввести комментарий(например параметры подготовки субстрата это пригодиться потом при анализе отчета). Нажать сканировать и сканировать попарно блок и его место(блок,место,блок,место) при успешном сканировании и отправки данных должен раздаваться звуковой сигнал, если сигнала нет необходимо прервать сканирование и повторить с пары на которой остановились.</p>
                <p>После успешного сканирования блоки начнут отображаться в веб интерфейсе в разделе учет и в камере, также можно посмотреть информацию о блоке в терминале экран информация о блоке нужно отсканировать сетку блока.</p>
                <p>В веб интерфейсе можно посмотреть что блок находиться в статусе инкубация и в соответствии с параметрами штамма блока сколько дней будет длиться инкубация, когда дни отведенные на инкубацию или на плодоношения закончиться в веб интерфейсе отобразиться что блок необходимо переносить.</p>
                <p>Когда блок необходимо переносить (по рекомендации системы, либо по решению сотрудника ) блок переноситься в камеру плодоношения при этом на терминале выбирается экран плодоношения вводиться комментарий по желанию сканируется блок место. После этого в веб интерфейсе можно посмотреть движение блоков в камере инкубации стало на один блок меньше а в камере плодоношения на один блок больше так же изменились показания блоков на инкубации и на плодоношении.</p>
                <img alt='' src='help/terminal/growing.png' height='500'></img>
                <p>Сбор гриба происходить по следующему алгоритму с блока срезаеться гриб взвешивается, на терминале выбирается экран сбор гриба вводиться вес и сканируется блок.</p>
                <img alt='' src='help/terminal/collection.png' height='500'></img>
                <p>После этапа плодоношения по рекомендации системы либо по усмотрению работника блок нужно утилизировать для этого на терминале необходимо перейти на экран утилизации выбрать причину утилизации, ввести комментарий по желанию отсканировать блок.</p>
                <img alt='' src='help/terminal/utilisation.png' height='500'></img>
                <p>После этого в веб интерфейсе в разделе отеч можно посмотреть всю информацию о блоке на каждых этапах, есть возможность скачать файл для анализа в программе электронных таблиц.</p>
            </div>
        );
    }
}