import moment from 'moment-timezone';

export function convertTime(timeUtc?: string, timeZone?: string) {
  if (!timeUtc) {
    return
  }
  
  var time = moment.tz(timeUtc, "UTC")
  // console.log("source time", time.format())
  var zone_name = moment.tz.guess();
  // console.log("zone_name", zone_name)
  var newTime = time.tz(zone_name).format("YYYY-MM-DD HH:mm:ss");
  //  console.log("new time  ", newTime)
  return newTime
}