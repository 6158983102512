import { FC, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DeviceProtocolType, DeviceUpdateDto } from 'api/types/device'
import { Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';


import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { } from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';


import { DeviceApi } from 'api';
import { DeviceProtocolTypeSelect } from '../../components/DeviceProtocolTypeSelect/DeviceProtocolTypeSelect';
import { v4 as uuidv4 } from 'uuid';


import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from './style';
import { DeviceMappingTable } from 'modules/device/components/deviceMappingTable/deviceMappingTable';



export const DeviceCreate: FC = () => {
    const history = useHistory();
    let params = useParams<{ id: string }>();

    let newItem = params.id == null;

    //debugger
    const [curentItem, setCreateItem] = useState<DeviceUpdateDto>(
        { id: params.id, name: "", description: "", isActive: true, apiKey: uuidv4(), protocolType: DeviceProtocolType.TCP_MSGPACK });

    const [load, setLoad] = useState(!newItem);

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setCreateItem(() => ({ ...curentItem, [name]: value }));
    };

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreateItem({ ...curentItem, [event.target.name]: event.target.checked });
    };

    const onSave = () => {
        if (newItem) {
            DeviceApi.add(curentItem)
                .then(data => {
                    history.push("/device/edit/" + data.data?.id)
                })
        } else {

            DeviceApi.update(curentItem)
                .then(data => {
                    history.push("/device")
                })
        }
    };

    useEffect(() => {
        let loadDevice = () => {
            DeviceApi.getById(params.id)
                .then((result) => {
                    setCreateItem(result.data);
                    setLoad(false);
                })
        }

        if (!newItem) {
            loadDevice();
        }


    }, [params, newItem]);


    let classes = useStyles();


    if (load) {
        return (
            <div>
                <Backdrop open={load} className={classes.backdrop} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }

    return (
        <Container maxWidth={false} >
            <div className="">
                <h2 style={{ display: 'inline-block', width: '600px' }}>{newItem ? 'Создание устройства' : 'Редактирование устройства'}</h2>
                <div
                    style={{
                        display: 'flex',
                        float: "right",
                        justifyContent: "flex-end",

                    }}>
                </div>
            </div>
            <div>
                <TextField name="name" id="standard-basic" label="Имя" value={curentItem?.name} onChange={handleChange} />
            </div>
            <div>
                <TextField name="description" id="standard-basic" label="Описание" value={curentItem?.description} onChange={handleChange} />
            </div>

            <div>
                <TextField name="apiKey" id="standard-basic" label="Ключ доступа" value={curentItem?.apiKey} onChange={handleChange} />
            </div>

            <div>
                <DeviceProtocolTypeSelect current={curentItem?.protocolType} onChangeItem={(newValue) => setCreateItem({ ...curentItem, protocolType: newValue })} />

            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={curentItem.isActive}
                            onChange={handleChangeCheckbox}
                            name="isActive"
                        // color="primary"
                        />
                    }
                    label="Включен"
                />
            </div>

            <div>
                <Button variant="contained" onClick={() => { onSave() }}>{newItem ? "Добавить" : "Сохранить"}</Button>
            </div>


            {newItem===false && <DeviceMappingTable deviceId={curentItem.id} />}

        </Container >



    );
}