import React, { Component } from 'react'
import { StrainApi } from '../../../api';

export default class StrainDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            strain: null,
        };
    }

    componentDidMount() {
        const { strainId } = this.props.match.params

        StrainApi.getById(strainId)
            .then((result) => {
                this.setState({
                    strain: result.data
                })
            });
    }

    render() {

        const { strain } = this.state

        if (strain === null) {
            return <div></div>
        }

        return (
            <div className="container-fluid  m-3">

                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-1 col-form-label">Название</label>
                    <div className="col-sm-3">
                        <p>{strain.name}</p>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputIncubationPeriod" className="col-sm-1 col-form-label">Сколько дней инкубации</label>
                    <div className="col-sm-3">
                        <p>{strain.incubationPeriod}</p>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputGrowingPeriod" className="col-sm-1 col-form-label">Сколько дней плодоношения</label>
                    <div className="col-sm-3">
                        <p>{strain.growingPeriod}</p>
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputDescription" className="col-sm-1 col-form-label">Описание</label>
                    <div className="col-sm-3">
                        <p>{strain.description}</p>
                    </div>
                </div>
            </div>
        );
    }
}