import HttpApi, { BaseHttpResponse } from './HttpApi';
import { Report } from './types/report';
import { Pagination } from './types/pagination'


export class ReportApi extends HttpApi {
    async getRange(from, to, page, size) {
        return await this.get<Pagination<Report>, BaseHttpResponse>('/' , { params: { from: from, to: to, page: page, size: size } });
    }

    async getCSV(from, to) {
        return await this.getBinary('/csv', {params: { from: from, to: to }, responseType: 'blob' });

    }
}
export default new ReportApi('/api/report');