import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { UserApi } from '../../../api';

export default class UserRegister extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOk: false,
            firstName: "",
            lastName: "",
            patronymic: "",
            password: "",
            passwordRepeat: "",
            phone: "",
            email: "",
            processingPersonalData: false
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleCreate = async () => {
        if (!this.state.processingPersonalData) {
            return
        }
        var create = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            patronymic: this.state.patronymic,
            password: this.state.password,
            phone: this.state.phone,
            email: this.state.email
        }
        console.log('onCreate', create)
        await UserApi.register(create)
            .then((result) => {
                console.log('response', result)
                //  this.props.history.push("/dashboard")
                this.setState({
                    isOk: true
                })
            }, (result) => {
                console.log('onCreate error', result)
            })
    }

    render() {

        if (this.state.isOk) {
            return (
                <div className="container-fluid m-3">
                    На вашу почту отправленна ссылка для активации акаунта.<br />
                    Если вы не получили данное письмо, проверьте папки «Спам» и «Удаленные», так как письмо могло автоматически туда перейти.

                </div>
            );
        }

        return (
            <div className="container-fluid m-3">

                <div className="col-sm-3">
                  <Link to="/resendActivateLink">Уже регистрировались? Выслать активацию повторно.</Link>
                </div>


                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-1 col-form-label">Имя</label>
                    <div className="col-sm-3">
                        <input type="text" className="form-control" name="firstName" id="inputName" placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="lastName" className="col-sm-1 col-form-label">Фамилия</label>
                    <div className="col-sm-3">
                        <input type="text" name="lastName" className="form-control" id="lastName" placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="patronymic" className="col-sm-1 col-form-label">Отчество</label>
                    <div className="col-sm-3">
                        <input type="text" name="patronymic" className="form-control" id="patronymic" placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="password" className="col-sm-1 col-form-label">Пароль</label>
                    <div className="col-sm-3">
                        <input type="password" name="password" className="form-control" id="password" placeholder="" autoComplete="new-password" onChange={this.handleInputChange} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="passwordRepeat" className="col-sm-1 col-form-label">Пароль еще раз</label>
                    <div className="col-sm-3">
                        <input type="password" name="passwordRepeat" className="form-control" id="passwordRepeat" placeholder="" autoComplete="new-password" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="phone" className="col-sm-1 col-form-label">Телефон</label>
                    <div className="col-sm-3">
                        <input type="text" name="phone" className="form-control" id="phone" placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="email" className="col-sm-1 col-form-label">Email</label>
                    <div className="col-sm-3">
                        <input type="text" name="email" className="form-control" id="email" placeholder="" autoComplete="username" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-2">Согласие на обработку персональных данных</div>
                    <div className="col-sm-1">
                        <div className="form-check">
                            <input className="form-check-input" name="processingPersonalData" type="checkbox" id="processingPersonalData" onChange={this.handleInputChange} />
                        </div>
                    </div>

                </div>


                <div className="form-group row">



                    <div className="col-sm-1">
                        <button type="button" className="btn btn-success" onClick={this.handleCreate}>Зарегистрироватся</button>

                    </div>
                </div>
            </div>
        );
    }
}