import { FC, useState, useEffect } from 'react';

import { DeviceDto } from 'api/types/device'
import { DeviceApi } from 'api';

import { TablePaginationActions } from 'modules/components/TablePaginationActions/TablePaginationActions'

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Container } from '@material-ui/core';

import { Link } from 'react-router-dom';

import {
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {TrashIcon } from '@primer/octicons-react'


const useStyles2 = makeStyles({
  table: {
    minWidth: 500
  }
});

export const DeviceList: FC = () => {
  const classes = useStyles2();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [items, setItems] = useState<DeviceDto[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);
  const [afterDeleteEvent, setAfterDeleteEvent] = useState(false);

  useEffect(() => {
    function getAll() {
      DeviceApi.getAll(page, rowsPerPage)
        .then((result) => {
          setItems(result.data.items);
          setTotalElements(result.data.totalElements)
        });
    }
    getAll();
  }, [page, rowsPerPage, afterDeleteEvent]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const removeItem = (id: string) => {
    DeviceApi.delete(id)
      .then(() => {
        setAfterDeleteEvent(!afterDeleteEvent);
      });
  }

  return (
    <Container maxWidth={false} >
      <div className="">
        <h2 style={{ display: 'inline-block', width: '200px' }}>Устройства</h2>

        <div
          style={{
            display: 'flex',
            float: "right",
            justifyContent: "flex-end",

          }}>

          <IconButton aria-label="upload picture" component={Link} to="/device/create">
            <AddIcon />
          </IconButton>

        </div>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell align="right">Описание</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {

              items.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <Link  to={`/device/edit/${row.id}`}>  {row.name}</Link>
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="right">
                    {row.description}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="right">
                    <Link onClick={() => { removeItem(row.id) }} to={"#"}> <TrashIcon size='medium' /></Link>
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[15, 30, 100, { label: "All", value: -1 }]}
                colSpan={3}
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container >
  );
}