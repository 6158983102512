import { createStore, applyMiddleware } from 'redux'
import reducers from './state'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
 // compose( // for multiple enhancers
 //   window.devToolsExtension ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
 // ),
)

export default store