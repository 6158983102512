import React, { Component } from 'react'
import AccountingApi from "../../api/accountingApi"
import { Status } from '../../api/types/accounting'

export default class Growing extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: null,
            intervalId: 0
        }
    }

    updateData = () => {

        AccountingApi.getByStatus(Status.GROWING)
            .then((result) => {
                this.setState(state => ({
                    data: result.data
                }));
            });
    }
    componentDidMount() {
        var intervalId = setInterval(this.updateData, 5000);
        this.setState({ intervalId: intervalId });
        this.updateData()
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }

    render() {
        const { data } = this.state

        return (

            <div className=".container-fluid   mt-3 mr-3">
                <div>
                    <h3 className=' text-center '>  Плодоношение </h3>
                </div>
                <div className="col-sm">
                    {data && <div className="">
                        <table className="table table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Партия</th>
                                    <th scope="col">Место</th>
                                    <th scope="col">Штамм</th>
                                    <th scope="col">Продолжительность</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row, index) =>
                                    <tr key={index}>
                                        <th scope="row">{row.batchBlockName}</th>
                                        <td>{row.chamberName}</td>
                                        <td>{row.strainName}</td>
                                        <td>{row.daysPassed}/{row.daysTotal}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>}
                </div>
            </div>
        );
    }

}