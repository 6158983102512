import React, { Component } from 'react'
import ChamberListItem from '../components/chamberListItem/chamberListItem'

import { Link } from 'react-router-dom';
import { ChamberApi } from '../../../api'

export default class ChamberList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            load: false,
            pageble: {}
        };
    }

    componentDidMount() {
        this.getAll()
    }

    getAll = () => {
        ChamberApi.getAll()
            .then((result) => {

                this.setState(state => ({
                    pageble: result.data,
                    load: true
                }));
            });
    }

    handlerDeleteItem = (id) => {
        ChamberApi.delete(id)
            .then(() => {
                this.getAll()
            });
    }

    render() {
        const { load, pageble } = this.state

        return (
            <div className=" container-fluid">
             
                    <div className="">
                        <Link to="/chamber/create" className="btn btn-success m-3">Добавить</Link>
                    </div>

                    <table className="table table-hover table-bordered">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Название</th>
                                <th scope="col">Описание</th>
                                <th scope="col">Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {load && pageble.items.map(item => <ChamberListItem key={item.id} item={item} handlerDeleteItem={this.handlerDeleteItem} />)}
                        </tbody>
                    </table>
            </div>
        );
    }
}