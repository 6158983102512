import React, { Component } from 'react'
import StrainListItem from '../components/strainListItem'
import { Link } from 'react-router-dom';
import { StrainApi } from '../../../api'

export default class StrainList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            load: false,
            items: []
        };
    }

    componentDidMount() {
        this.getAll()
    }

    getAll = () => {
        StrainApi.getAll()
            .then((result) => {
 ///todo пагинация 
                this.setState(state => ({
                    items: result.data.items,
                    load: true
                }));
            });
    }

    handlerDeleteItem = (id) => {
        StrainApi.delete(id)
            .then(() => {
                this.getAll()
            });
    }

    render() {
        const { load, items } = this.state

        return (
            <div className=" container-fluid">
                <div className="">
                    <Link to="/strain/create" className="btn btn-success m-3">Добавить</Link>
                </div>

                <table className="table table-hover table-bordered">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Название</th>
                            <th scope="col">Описание</th>
                            <th scope="col">Действие</th>
                        </tr>
                    </thead>
                    <tbody>
                        {load && items.map(item => <StrainListItem key={item.id} item={item} handlerDeleteItem={this.handlerDeleteItem} />)}
                    </tbody>
                </table>
            </div>
        );
    }
}