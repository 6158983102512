import HttpApi, { BaseHttpResponse } from './HttpApi';
import { Pagination } from './types/pagination'
import { StrainCreateDto, StrainDto} from './types/strain';


export class StrainApi extends HttpApi {
    async getAll() {
        return await this.get<Pagination<StrainDto>, BaseHttpResponse>('/');
    }

    async getById(id: string) {
        return await this.get<StrainDto, BaseHttpResponse>('/' + id);
    }

    async update(strain: StrainCreateDto){
        return await this.put<StrainCreateDto, BaseHttpResponse>('/', strain);
    }

    async create(strain: StrainCreateDto) {
        return await this.post<StrainCreateDto, BaseHttpResponse>('/', strain);
    }
}
export default new StrainApi('/api/strain');