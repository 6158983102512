//import { User } from '../api/types/user';


const initialState = {
  user: null
}


export function login(user) {
  return {
    type: 'USER_LOGIN',
    user: user
  }
}
export function logout() {
  return {
    type: 'USER_LOGIN',
    user: null
  }
}


export default function userReduser (state = initialState, action) {

  switch (action.type) {
    case 'USER_LOGIN':
      return Object.assign({}, state, { user: action.user });
  }
  return state;
}