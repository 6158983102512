import HttpApi, { BaseHttpResponse } from './HttpApi';
import { DeviceDto, DeviceCreateDto, DeviceUpdateDto } from './types/device';
import { Pagination } from './types/pagination';


export class DeviceApi extends HttpApi {

  async getAll(page: number, rowsPerPage: number) {
    return await this.get<Pagination<DeviceDto>, BaseHttpResponse>('/', { params: { page: page, limit: rowsPerPage } });
  }

  async getById(id: string) {
    return await this.get<DeviceDto, BaseHttpResponse>('/' + id);
  }

  async add(device: DeviceCreateDto) {
    return await this.post<DeviceDto, BaseHttpResponse>('/', device);
  }

  async update(device: DeviceUpdateDto) {
    return await this.put<DeviceDto, BaseHttpResponse>('/', device);
  }

}
export default new DeviceApi('/api/device');