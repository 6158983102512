import { FC, useState, useEffect } from 'react';
import { VariableDto, VariableType } from 'api/types/variable'
import { Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { AvailableVariableTypes } from 'modules/components/AvailableVariableTypes/AvailableVariableTypes';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { } from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import { VariableApi } from 'api';
import { useParams } from "react-router-dom";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

export const VariableEdit: FC = () => {
    const history = useHistory();
    let params = useParams<{ id: string }>();
    const [loaded, setLoaded] = useState(true);
    const [createItem, setCreateItem] = useState<VariableDto>(
        { id: "", description: "", name: "", isActive: false, isDeleted: false, isPersist: false, valueType: VariableType.INT });

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setCreateItem(() => ({ ...createItem, [name]: value }));
    };

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreateItem({ ...createItem, [event.target.name]: event.target.checked });

    };

    const onSave = () => {

        VariableApi.update(createItem)
            .then(data => {

                //setItem(data.data);
                history.push("/variable")
            })
    };

    useEffect(() => {
        VariableApi.getById(params.id)
            .then((result) => {
                setCreateItem(result.data);
                setLoaded(false);
            });
    }, [params]);


    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            backdrop: {
                zIndex: theme.zIndex.drawer + 1,
                color: '#fff',
            },
        }),
    );

    const classes = useStyles();


    if (loaded) {
        return (
            <div>

                <Backdrop open={loaded} className={classes.backdrop} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }


    return (





        <Container maxWidth={false} >
            <div className="">
                <h2 style={{ display: 'inline-block', width: '500px' }}>Редактирование переменной</h2>
                <div
                    style={{
                        display: 'flex',
                        float: "right",
                        justifyContent: "flex-end",

                    }}>
                </div>
            </div>




            <div>
                <TextField name="name" id="standard-basic" label="Имя" value={createItem?.name} onChange={handleChange} />
            </div>
            <div>
                <TextField name="description" id="standard-basic" label="Описание" value={createItem?.description} onChange={handleChange} />
            </div>
            <div>
                <AvailableVariableTypes current={createItem?.valueType} onChangeItem={(newValue) => newValue && setCreateItem({ ...createItem, valueType: newValue })} />
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={createItem.isActive}
                            value={createItem.isActive}
                            onChange={(event) => { handleChangeCheckbox(event) }}
                            name="isActive"
                        // color="primary"
                        />
                    }
                    label="Включен"
                />

            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            value={createItem.isPersist}
                            checked={createItem.isPersist}
                            onChange={handleChangeCheckbox}
                            name="isPersist"
                        // color="primary"
                        />
                    }
                    label="Хранить историю"
                />
            </div>
            <div>
                <Button variant="contained" onClick={() => { onSave() }}>Сохранить</Button>
            </div>
        </Container >
    );
}