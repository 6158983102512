import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { Link } from 'react-router-dom';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import HomeIcon from '@material-ui/icons/Home';
import AssessmentIcon from '@material-ui/icons/Assessment';
import HelpIcon from '@material-ui/icons/Help';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ToysIcon from '@material-ui/icons/Toys';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';

interface Props {
    children?: ReactNode;
}

export const MiniDrawer: FC<Props> = ({
    children
}) => {

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

   // const user = useSelector((state: any) => state.user);
    
  //  const posts = useSelector((state: ) => state.posts);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const location = useLocation();

    return (
        <div className={classes.root}>
            <CssBaseline />
            {/*  <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Mini variant drawer
                    </Typography>
                </Toolbar>
            </AppBar> */}
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                        {theme.direction === 'rtl' || !open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button component={Link} to="/dashboard" selected={location.pathname.includes("/dashboard")}>
                        <ListItemIcon> <DashboardIcon /> </ListItemIcon>
                        <ListItemText primary="Рабочий стол" />
                    </ListItem>



                    <ListItem button component={Link} to="/variable" selected={location.pathname.includes("/variable")}>
                        <ListItemIcon> <SettingsInputComponentIcon /> </ListItemIcon>
                        <ListItemText primary="Переменные" />
                    </ListItem>



                    <ListItem button component={Link} to="/device" selected={location.pathname.includes("/device")} >
                        <ListItemIcon> <ImportExportIcon /> </ListItemIcon>
                        <ListItemText primary="Устройства" />
                    </ListItem>
                    <ListItem button component={Link} to="/chamber" selected={location.pathname.includes("/chamber")}>
                        <ListItemIcon> <HomeIcon /> </ListItemIcon>
                        <ListItemText primary="Камеры" />
                    </ListItem>
                    <ListItem button component={Link} to="/accounting" selected={location.pathname.includes("/accounting")}>
                        <ListItemIcon> <AccountBalanceIcon /> </ListItemIcon>
                        <ListItemText primary="Учет" />
                    </ListItem>
                    <ListItem button component={Link} to="/strain" selected={location.pathname.includes("/strain")}>
                        <ListItemIcon> <ToysIcon /> </ListItemIcon>
                        <ListItemText primary="Штаммы" />
                    </ListItem>
                    <ListItem button component={Link} to="/report" selected={location.pathname.includes("/report")}>
                        <ListItemIcon> <AssessmentIcon /> </ListItemIcon>
                        <ListItemText primary="Отчет" />
                    </ListItem>
                    <ListItem button component={Link} to="/help" selected={location.pathname.includes("/help")}>
                        <ListItemIcon> <HelpIcon /> </ListItemIcon>
                        <ListItemText primary="Помощь" />
                    </ListItem>
                </List>
                <Divider />
                <List className="footer">
                    <ListItem button component={Link} to="/user/profile" selected={location.pathname.includes("/user/profile")}>
                        <ListItemIcon> <AccountCircleIcon /> </ListItemIcon>
                        <ListItemText primary="Профиль" />
                    </ListItem>
                </List>
                <ListItem button component={Link} to="/logout" selected={location.pathname.includes("/logout")}>
                    <ListItemIcon> <ExitToAppIcon /> </ListItemIcon>
                    <ListItemText primary="Выход" />
                </ListItem>
            </Drawer>
            {<main className={classes.content}>
                {children}
            </main>}
        </div>
    );
}
