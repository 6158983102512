import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        comboBox: {
            width: "200px",
            marginTop:"25px"
        },
    }),
);
