import React, { Component } from 'react'
import { ChamberReadingsApi } from '../../../../api'
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import { convertTime } from '../../../../utils/timeUtils'

import DateTimeRangePicker from '../../../components/dateTimeRangePicker/dateTimeRangePicker'

export default class ChamberChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    handleShow = () => {
       // debugger
        ChamberReadingsApi.getRange(this.props.chamberId, this.state.dateFrom.toISOString(), this.state.dateTo.toISOString())
            .then((result) => {
                
                result.data.forEach(item => {
                    item.dateTime = convertTime(item.dateTime)
                })
                this.setState({
                    data: result.data
                })
            })
    }

    render() {

        const { data } = this.state;

        return (
            <div className="container">

                <DateTimeRangePicker
                    onChangeFrom={dateFrom => {
                        
                        debugger
                        this.setState({ dateFrom }) }}
                    onChengeTo={dateTo => { this.setState({ dateTo }) }}
                    onClickBuuton={this.handleShow}
                    onCreate={(dateFrom, dateTo) => {
                        
                        this.setState({
                            dateFrom,
                            dateTo
                        }, this.handleShow)
                    }} />

                <div className=" d-flex justify-content-center">

                    <LineChart
                        className=" "
                        width={700}
                        height={300}
                        data={data}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="dateTime" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="linear" dataKey="value" name="Количество" stroke="red" activeDot={{ r: 8 }} />

                    </LineChart>
                </div>
            </div>
        );
    }
}