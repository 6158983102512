import React, { Component } from 'react'
import { UserApi } from '../../../api';

export default class RequestNewPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            isOk: false
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleButton = async () => {
        var requestNewPassword = {
            email: this.state.email
        }
        console.log('onCreate', this.state)
        await UserApi.requestNewPassword(requestNewPassword)
            .then((result) => {
                console.log('response', result)
                this.setState({
                    isOk: true
                })
            }, (result) => {
                console.log('onCreate error', result)
            })
    }

    render() {

        if (this.state.isOk) {
            return (
                <div className="container-fluid m-3">
                    На вашу почту отправленна ссылка для сброса пароля.<br/> 
                    Если вы не получили данное письмо, проверьте папки «Спам» и «Удаленные», так как письмо могло автоматически туда перейти.
                </div>
            );
        }

        return (
            <div className="container-fluid m-3">
                <div className="form-group row">
                    <label htmlFor="inputDescription" className="col-sm-1 col-form-label">Email</label>
                    <div className="col-sm-3">
                        <input type="text" name="email" className="form-control" id="inputDescription" placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-10">
                        <button type="button" className="btn btn-success" onClick={this.handleButton}>Сбросить пароль</button>
                    </div>
                </div>
            </div>
        );
    }
}