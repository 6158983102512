import { FC } from 'react';
import Draggable from "react-draggable";
import { DashboardItem } from 'api/types/dashboard';
import { DashboardItemName } from '../dashboardItemName/dashboardItemName'


interface Props {
    item: DashboardItem;
    onDelete: (a: string) => void;
}

export const DashboardItemBlank: FC<Props> = ({
    item,
    onDelete,
}) => {

    return (
        <Draggable>
            <div className="card" style={{ width: "15rem", height: "15rem", margin: "1rem" }} >
                <div className="card-body">
                <DashboardItemName id={item.id} name={item.name} onDelete={onDelete} />


                    {
                        <p className="card-text text-center"></p>
                    }
                </div>
            </div>
        </Draggable>


    )

}