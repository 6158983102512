import { FC, useState, useEffect } from 'react';



import { DashboardApi, DashboardItemApi } from '../../api'
import { DashboardDto, DashboardItemCreateDto, DashboardItem } from 'api/types/dashboard'

import { DashboardItemCounter } from "./components/dashboardItemCounter/dashboardItemCounter"
import { DashboardItemBlank } from "./components/dashboardItemBlank/dashboardItemBlank"
import { DashboardItemChart } from "./components/dashboardItemChart/dashboardItemChart"
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
//import SaveIcon from '@material-ui/icons/Save';
import { Container } from '@material-ui/core';
import DateTimeRangePicker from '../components/dateTimeRangePicker/dateTimeRangePicker'
//import { DateTimeRangePicker } from '../components/dateTimeRangePicker/dateTimeRangePicker';

import { RefreshControl } from 'modules/components/refreshControl/refreshControl'

// import { TimeRangePicker } from '@grafana/ui';
export const Dashboard: FC = () => {

    const [load, setLoad] = useState<boolean>(false);
    const [dashboard, setDashboard] = useState<DashboardDto | any>();
    const [dateFrom, setDateFrom] = useState<Date>();
    const [dateTo, setDateTo] = useState<Date>();
    const [refreshInterval, setRefreshInterval] = useState<number>(5000);
    const [needUpdate, setNeedUpdate] = useState<boolean>(false);
    useEffect(() => {
        DashboardApi.getDashboard().then((result) => {
            setDashboard(result.data);
            setLoad(true);
        });
    }, []);


    const addElement = () => {
        let items = dashboard.items;
        var element = {} as DashboardItemCreateDto;
        element.dashboardId = dashboard.id;
        element.name = 'Новый элемент';
        element.position = items.length + 1;
        DashboardItemApi.create(element)
            .then((result) => {
                var newDashbord = Object.assign({}, dashboard);
                newDashbord.items.push(result.data)
                setDashboard(newDashbord);
            })
    }

    // const onSave = () => {
    //     window.console.log("сохраняем", dashboard)
    //     DashboardApi.update(dashboard)
    //         .then(() => {
    //             window.console.log("сохранили", dashboard)
    //         });
    // }

    const onDelete = (id: string) => {
        var newDashbord = Object.assign({}, dashboard);
        newDashbord.items = dashboard.items.filter((item: DashboardItem) => item.id !== id);
        setDashboard(newDashbord);
        window.console.log("сохраняем", newDashbord)
        DashboardApi.update(newDashbord)
            .then(() => {
                window.console.log("сохранили", newDashbord)
            });
    }

    if (load === false) {
        return (<div></div>);
    }

    let itemRender = dashboard.items.map(item => {
        switch (item.type) {
            case 'BLANK': {
                return <DashboardItemBlank item={item} key={item.id} onDelete={onDelete} />;
            }
            case 'COUNTER': {
                return <DashboardItemCounter refreshInterval={refreshInterval} item={item} key={item.id} onDelete={onDelete} />;
            }
            case 'CHART': {
                return <DashboardItemChart refreshInterval={refreshInterval} item={item} key={item.id} from={dateFrom} to={dateTo} onDelete={onDelete} />;
            }
            default: {
                return <div></div>;
            }
            case null: {
                return <div></div>;
            }
        }
    })

    return (
        <Container maxWidth={false} >
            <div className="">
                <h2 style={{ display: 'inline-block', width: '200px' }}>{dashboard.name}</h2>

                <div
                    style={{
                        display: 'flex',
                        float: "right",
                        justifyContent: "flex-end",

                    }}>
                    {/* <DateTimeRangePicker /> */}
                    <DateTimeRangePicker
                        onChange={(ewDateFrom, newDateTo) => {
                            setDateFrom(ewDateFrom);
                            setDateTo(newDateTo);
                        }}
                    
                    />
                    <RefreshControl
                        onRefreshEvent={() => { setNeedUpdate(!needUpdate); }}
                        onNewRefreshIntervaEvent={(newInterval) => {
                            //debugger
                            window.console.log("onRefreshEvent: " + newInterval);
                            setRefreshInterval(newInterval);


                        }} />

                    <IconButton onClick={addElement} aria-label="upload picture" component="span">
                        <AddIcon />
                    </IconButton>
                    {/* <IconButton onClick={onSave} aria-label="upload picture" component="span">
                        <SaveIcon />
                    </IconButton> */}
                </div>
            </div>

            {/* <TimeRangePicker/> */}

            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: '20px', zIndex: 1 }}>
                {itemRender}
            </div>
        </Container >
    );

}

