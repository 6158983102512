import React,{Component} from 'react'
import { Link } from 'react-router-dom';
import { TrashIcon, GitBranchIcon } from '@primer/octicons-react'

export default class StrainListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activate: false
        };
    }

    render() {
        const { item, handlerDeleteItem } = this.props
        return (
            <tr className="nodeData">
                <th scope="row"><Link to={`/strain/details/${item.id}`}  >{item.name}</Link></th>
                <td>{item.description}</td>
                <td>
                    <Link to={`/strain/copy/${item.id}`}> <GitBranchIcon size='medium' /></Link>
                    {/* <Link to={`/strain/edit/${item.id}`}> <Octicon className='mr-3' icon={Pencil} size='medium' /></Link> */}
                    <Link onClick={() => { handlerDeleteItem(item.id) }} to={"#"}> <TrashIcon size='medium' /></Link>
                </td>
            </tr>
        );
    }
}