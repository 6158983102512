import { FC, useState, useEffect } from 'react';

import { DeviceValueMapApi } from 'api';

import { TablePaginationActions } from 'modules/components/TablePaginationActions/TablePaginationActions'

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Container } from '@material-ui/core';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useStyles } from './styles';
import { DeviceValueMapDto } from 'api/types/deviceValueMap';
import { DeviceMappingTableRow } from './deviceMappingTableRow/deviceMappingTableRow';
import { VariableType } from 'api/types/variable';
import { v4 as uuidv4 } from 'uuid';



interface Props {
  deviceId: string;
}

export const DeviceMappingTable: FC<Props> = ({
  deviceId
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [items, setItems] = useState<DeviceValueMapDto[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);
  const [afterDeleteEvent, setAfterDeleteEvent] = useState(false);

  useEffect(() => {
    function getAll() {
      DeviceValueMapApi.getAll(deviceId, page, rowsPerPage)
        .then((result) => {
          setItems(result.data.items);
          setTotalElements(result.data.totalElements)
        });
    }
    getAll();
  }, [deviceId, page, rowsPerPage, afterDeleteEvent]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onDelete = (id: string) => {
    DeviceValueMapApi.delete(id)
      .then(() => {
        setAfterDeleteEvent(!afterDeleteEvent);
      });
  }

  const onSave = (updatedItem: DeviceValueMapDto) => {
    console.log(updatedItem);
    if (updatedItem.id.startsWith("new")) {
      let createItem  = { ...updatedItem, id: "" };
      debugger
      DeviceValueMapApi.add(createItem)
        .then((result) => {

          setItems(items.map((item) => {
            return item.id === updatedItem.id ? result.data : item;
          })
          );
        });
    } else {
      DeviceValueMapApi.update(updatedItem)
        .then(() => {
        })
    }
  };

  const addRow = () => {
    setItems([{
      id: "new" + uuidv4(),
      name: "",
      description: "",
      deviceId: deviceId,
      directionType: "",
      isActive: true,
      isDeleted: false,
      key: "",
      type: VariableType.INT
    }, ...items])
  };


  return (
    <Container maxWidth={false} >
      <div className="">
        <h3 style={{ display: 'inline-block', width: '400px' }}>Настройка мапинга</h3>

        <div
          style={{
            display: 'flex',
            float: "right",
            justifyContent: "flex-end",

          }}>

          <IconButton onClick={addRow} >
            <AddIcon />
          </IconButton>



        </div>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>Название</TableCell>
              <TableCell>Тип данных</TableCell>
              <TableCell>Ключ</TableCell>
              <TableCell>Переменная</TableCell>
              <TableCell>Направление</TableCell>
              <TableCell>Описание</TableCell>
              <TableCell align="right">Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              items.map((row) => (
                <DeviceMappingTableRow key={row.id} deviceValueMapDto={row} onSave={onSave} onDelete={onDelete} />
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[15, 30, 100, { label: "All", value: -1 }]}
                colSpan={3}
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container >
  );
}