import React, { Component } from 'react'
import AccountingApi from "../../api/accountingApi"
import { dowloadFile } from '../../utils/downloadFileUtils'
import { Status } from '../../api/types/accounting'
import { Link } from 'react-router-dom';
export default class Accounting extends Component {

    constructor(props) {
        super(props)
        this.state = {
            incubationData: null,
            growingData: null,
            intervalId: 0
        }
    }

    updateData = () => {
        AccountingApi.getTransferByStatus(Status.INCUBATION)
            .then((result) => {


                this.setState(state => ({

                    incubationData: result.data

                }));
            });

        AccountingApi.getTransferByStatus(Status.GROWING)
            .then((result) => {
                this.setState(state => ({
                    growingData: result.data
                }));
            });
    }
    componentDidMount() {
        var intervalId = setInterval(this.updateData, 5000);
        this.setState({ intervalId: intervalId });
        this.updateData()
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }


    downloadPdf = () => {
        // const { chamberId } = this.props.match.params
        AccountingApi.getBlockCodes(240)
            .then((response) => {
                dowloadFile(response)
            });
    }

    render() {
        const { incubationData, growingData } = this.state

        return (

            <div className=".container-fluid  ml-5 mt-3 mr-3">

                <div>
                    <button type="button" className="btn btn-primary" onClick={this.downloadPdf}>Скачать метки для блоков</button>
                    <h3 className=' text-center '>  Учет блоков </h3>
                </div>

                <div className='row mt-2' >
                    <div className="col-sm">

                        {incubationData &&
                            <div className="">
                                <div><p><Link to='/accounting/incubation'>Инкубация:</Link> <b>{incubationData.currentCount}</b></p></div>
                                <div><p>Ожидают переноса:<b> {incubationData.transferCount}</b></p></div>

                                <table className="table table-bordered">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Партия</th>
                                            <th scope="col">Место</th>
                                            <th scope="col">Штамм</th>
                                            <th scope="col">Продолжительность</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {incubationData.transfers.map((row, index) =>
                                            <tr key={index}>
                                                <th scope="row">{row.batchBlockName}</th>
                                                <td>{row.chamberName}</td>
                                                <td>{row.strainName}</td>
                                                <td>{row.daysPassed}/{row.daysTotal}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>}
                    </div>
                    <div className="col-sm">
                        {growingData && <div className="">
                            <div><p><Link to='/accounting/growing'> Плодоношение:</Link> <b>{growingData.currentCount}</b></p></div>
                            <div><p>Ожидают переноса: <b>{growingData.transferCount}</b></p></div>

                            <table className="table table-bordered">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Партия</th>
                                        <th scope="col">Место</th>
                                        <th scope="col">Штамм</th>
                                        <th scope="col">Продолжительность</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {growingData.transfers.map((row, index) =>
                                        <tr key={index}>
                                            <th scope="row">{row.batchBlockName}</th>
                                            <td>{row.chamberName}</td>
                                            <td>{row.strainName}</td>
                                            <td>{row.daysPassed}/{row.daysTotal}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>}
                    </div>
                </div>
            </div>);
    }

}