import { FC, useState, } from 'react';
import { useHistory } from 'react-router-dom';
import { VariableCreateDto, VariableType } from 'api/types/variable'
import { Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { AvailableVariableTypes } from 'modules/components/AvailableVariableTypes/AvailableVariableTypes';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { } from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';


import { VariableApi } from 'api';


export const VariableCreate: FC = () => {
    const history = useHistory();

    const [createItem, setCreateItem] = useState<VariableCreateDto>(
        { name: "", description: "", isActive: true, isDeleted: false, isPersist: false, valueType: VariableType.INT });

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setCreateItem(() => ({ ...createItem, [name]: value }));
    };

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreateItem({ ...createItem, [event.target.name]: event.target.checked });
    };

    const onSave = () => {
        // debugger
        VariableApi.add(createItem)
            .then(data => {
                //setItem(data.data);
                history.push("/variable")
            })
    };

    return (
        <Container maxWidth={false} >
            <div className="">
                <h2 style={{ display: 'inline-block', width: '400px' }}>Создание переменной</h2>
                <div
                    style={{
                        display: 'flex',
                        float: "right",
                        justifyContent: "flex-end",

                    }}>
                </div>
            </div>
            <div>
                <TextField name="name" id="standard-basic" label="Имя" value={createItem?.name} onChange={handleChange} />
            </div>
            <div>
                <TextField name="description" id="standard-basic" label="Описание" value={createItem?.description} onChange={handleChange} />
            </div>
            <div>
                <AvailableVariableTypes current={createItem?.valueType} onChangeItem={(newValue) => newValue &&  setCreateItem({ ...createItem, valueType: newValue })} />
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={createItem.isActive}
                            onChange={handleChangeCheckbox}
                            name="isActive"
                        // color="primary"
                        />
                    }
                    label="Включен"
                />
            </div>
            <div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={createItem.isPersist}
                            onChange={handleChangeCheckbox}
                            name="isPersist"
                        // color="primary"
                        />
                    }
                    label="Хранить историю"
                />
            </div>
            <div>
                <Button variant="contained" onClick={() => { onSave() }}>Сохранить</Button>
            </div>
        </Container >
    );
}