import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import qs from 'query-string';
import { AuthResponse } from './types/auth';

import { toLocalTime } from '../utils/date';


export interface BaseHttpResponse<T = any> {
  data?: T;
  error?: string;
}


const API_URL = process.env.REACT_APP_API_URL || '/api';
const LOGIN_ROUTE = '/login';


export default abstract class HttpApi {
  protected http: AxiosInstance;

  constructor(protected basePath: string) {
    this.http = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}${basePath}`,
      timeout: 30000,
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'none' });
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.http.interceptors.request.use(
      async (config) => {
        config.headers['Authorization'] = await this.getToken();
        //debugger
        return config;
      },
      err => Promise.reject(err),
    );
    //debugger
  }




  getPostBinary(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.auth(this.http.post(url, data, config));
  }

  getBinary(url: string, config?: AxiosRequestConfig) {
    return this.auth(this.http.get(url, config));
  }

  get<K, T extends BaseHttpResponse<K>>(url: string, config?: AxiosRequestConfig) {
    return this.response<K, T>(this.auth(this.http.get(url, config)));
  }

  post<K, T extends BaseHttpResponse<K>>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.response<K, T>(this.auth(this.http.post(url, data, config)));
  }

  put<K, T extends BaseHttpResponse<K>>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.response<K, T>(this.auth(this.http.put(url, data, config)));
  }

  patch<K, T extends BaseHttpResponse<K>>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.response<K, T>(this.auth(this.http.patch(url, data, config)));
  }

  delete<K, T extends BaseHttpResponse<K>>(url: string, config?: AxiosRequestConfig) {
    return this.response<K, T>(this.auth(this.http.delete(url, config)));
  }



  private async getToken(): Promise<string> {
    const jwtExpire = localStorage.getItem('tokenExpire')!;

    const isTokenExpired = jwtExpire != null && toLocalTime(new Date(jwtExpire)) < new Date();

    if (isTokenExpired) {
      await this.refreshToken();
    }

    return localStorage.getItem('token')!;
  }
  private async refreshToken() {
    const refreshExpire = localStorage.getItem('refreshExpire')!;
    const isTokenExpired = refreshExpire != null && toLocalTime(new Date(refreshExpire)) < new Date();
    if (isTokenExpired) {
      this.clearAuthCredentials();
    }

    const refreshToken = localStorage.getItem('refreshToken')!;

    try {
      const data = await axios.post<BaseHttpResponse<AuthResponse>>(
        '/api/auth/refresh',
        null,
        {
          params: { refreshToken },
          baseURL: API_URL,
        },
      );

      for (const [key, val] of Object.entries(data)) {
        localStorage.setItem(key, val);
      }
    }
    catch (error) {
      if (axios.isAxiosError(error)) {

        var axiosError = error as AxiosError;

        if (axiosError?.response?.status === 500) {
          this.clearAuthCredentials();
        }
      }

    }
  }

  private async response<K, T extends BaseHttpResponse<K>>(request: AxiosPromise<T>) {
    const response: AxiosResponse<T> = await request;
    const { data: responseData } = response;


    if (responseData != null && responseData.error != null) {
      throw new Error(responseData.error);
    }

    // Not all apis respond in the same format
    if (!responseData.hasOwnProperty('data')) {
      return (responseData as unknown) as T;
    }

    return response.data;
  }

  private async auth<T = any>(request: AxiosPromise<T>): Promise<AxiosResponse<T>> {
    try {
      return await request;
    }
    catch (error) {
      this.clearAuthCredentials();
       if (axios.isAxiosError(error)) {
        const { response }: AxiosError = error;

        if (response != null && response.status === 403 && window.location.pathname !== LOGIN_ROUTE) {
          this.clearAuthCredentials();
        }
      } 
      throw error;
    }
  }

  private clearAuthCredentials() {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpire');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshExpire');
    window.location.href = LOGIN_ROUTE;
  }
}



