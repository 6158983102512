import React, { Component } from 'react'
import { UserApi } from '../../../api';

export default class UserProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current: {},
            new: {}
        };
    }

    componentDidMount() {
        UserApi.getMe()
            .then((result) => {
                this.setState(() => ({
                    current: result.data
                }));
            });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var newValues = Object.assign({}, this.state.new);
        newValues[name] = value

        this.setState(state => {
            state.new = newValues
        });
    }

    handleCreate = async () => {
        console.log('update', this.state.new)
        await UserApi.update(this.state.new)
            .then((result) => {
                console.log('response', result)
                this.setState({
                    isOk: true
                })
            }, (result) => {
                console.log('update error', result)
            })
    }

    render() {

        return (
            <div className="container-fluid m-3">
                <form>
                    <div className="form-group row">
                        <label htmlFor="inputName" className="col-sm-1 col-form-label">Имя</label>
                        <div className="col-sm-3">
                            <input type="text" className="form-control" name="firstName" id="inputName" defaultValue={this.state.current.firstName} placeholder="" onChange={this.handleInputChange} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="lastName" className="col-sm-1 col-form-label">Фамилия</label>
                        <div className="col-sm-3">
                            <input type="text" name="lastName" className="form-control" id="lastName" defaultValue={this.state.current.lastName} placeholder="" onChange={this.handleInputChange} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="patronymic" className="col-sm-1 col-form-label">Отчество</label>
                        <div className="col-sm-3">
                            <input type="text" name="patronymic" className="form-control" id="patronymic" defaultValue={this.state.current.patronymic} placeholder="" onChange={this.handleInputChange} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="password" className="col-sm-1 col-form-label">Пароль</label>
                        <div className="col-sm-3">
                            <input type="password" name="password" className="form-control" id="password" placeholder="" defaultValue={this.state.current.password} autoComplete="new-password" onChange={this.handleInputChange} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="passwordRepeat" className="col-sm-1 col-form-label">Пароль еще раз</label>
                        <div className="col-sm-3">
                            <input type="password" name="passwordRepeat" className="form-control" id="passwordRepeat" placeholder="" autoComplete="new-password" onChange={this.handleInputChange} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="phone" className="col-sm-1 col-form-label">Телефон</label>
                        <div className="col-sm-3">
                            <input type="text" name="phone" className="form-control" id="phone" defaultValue={this.state.current.phone} placeholder="" onChange={this.handleInputChange} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label htmlFor="timeZone" className="col-sm-1 col-form-label">Часовой пояс</label>
                        <div className="col-sm-3">
                            <input type="text" name="timeZone" className="form-control" id="timeZone" defaultValue={this.state.current.timeZone} placeholder="" onChange={this.handleInputChange} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-sm-10">
                            <button type="button" className="btn btn-success" onClick={this.handleCreate}>Обновить</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}