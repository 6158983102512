import React, { Component } from 'react'
import { StrainApi } from '../../../api';

export default class StrainEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            strain: null,
        };
    }

    componentDidMount() {
        const { strainId } = this.props.match.params

        if (strainId === null) {
            return
        }

        StrainApi.getById(strainId)
            .then((result) => {
                this.setState({
                    strain: result
                })
            });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var strain = Object.assign({}, this.state.strain);
        strain[name] = value

        this.setState(state => {
            state.strain =   strain
        });
    }

    handleCreate = async () => {
        console.log('onCreate', this.state.strain)
        await StrainApi.update(this.state.strain)
            .then((result) => {
                console.log('response', result)
                this.props.history.push("/strain")
            }, (result) => {
                console.log('onCreate error', result)
            })
    }

    render() {

        if (this.state.strain === null) {
            return <div></div>
        }

        const { strain } = this.state

        return (
            <div className="container-fluid  m-3">

                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-1 col-form-label">Название</label>
                    <div className="col-sm-3">
                        <input type="text" className="form-control" name="name" id="inputName" defaultValue={strain.name} placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputIncubationPeriod" className="col-sm-1 col-form-label">Сколько дней инкубации</label>
                    <div className="col-sm-3">
                        <input type="text" className="form-control" name="incubationPeriod" id="inputIncubationPeriod" defaultValue={strain.incubationPeriod} placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputGrowingPeriod" className="col-sm-1 col-form-label">Сколько дней плодоношения</label>
                    <div className="col-sm-3">
                        <input type="text" className="form-control" name="growingPeriod" id="inputGrowingPeriod" defaultValue={strain.growingPeriod} placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputDescription" className="col-sm-1 col-form-label">Описание</label>
                    <div className="col-sm-3">
                        <input type="text" name="description" className="form-control" id="inputDescription" defaultValue={strain.description} placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-10">
                        <button type="button" className="btn btn-primary" onClick={this.handleCreate}>Сохранить</button>
                    </div>
                </div>
            </div>
        );
    }
}