import { DeviceProtocolType } from 'api/types/device';
import { FC, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStyles } from './styles';




interface Props {
    current: DeviceProtocolType | null | undefined,
    onChangeItem: any
}


export const DeviceProtocolTypeSelect: FC<Props> = ({
    current,
    onChangeItem
}) => {


    const [items] = useState<DeviceProtocolType[]>([
        DeviceProtocolType.HTTPS,
        DeviceProtocolType.TCP_MSGPACK,
    ]);
    const [value, setValue] = useState<DeviceProtocolType | null | undefined>(current);

  
    useEffect(() => {
        const sele = items.find(element => element === current);
        sele && setValue(sele);
    }, [current, items]);


    const classes = useStyles();


    return(

        <Autocomplete
        className={classes.comboBox}
        id="comboBox"
        options={items}
        getOptionLabel={(option) => option.toString()}
        value={value ? value : null}
        getOptionSelected={(option, value) => {

            return option === value
        }}

        onChange={(_event: any, newValue: DeviceProtocolType | null) => {
            setValue(newValue);
            onChangeItem(newValue);
        }}
        renderInput={(params) => <TextField {...params} label="Тип" variant="outlined" />}
    />
    );
}