import { FC, useState, useEffect } from 'react';
import { DashboardItem } from 'api/types/dashboard';
import { Reading } from 'api/types/variable';
import { VariableValueApi } from 'api';
import { convertTime } from 'utils/timeUtils'


interface Props {
    item: DashboardItem;
    refreshInterval: number;
}

export const DashboardItemEditCounter: FC<Props> = ({
    item,
    refreshInterval,
}) => {

    const [reading, setReading] = useState<Reading>({ value: 0, dateTime: "" });
    //  const [intervalId, setIntervalId] = useState<any>();

    useEffect(() => {

        const updateValue = () => {
            window.console.log("Запрос значений для", item)
            item.variable && VariableValueApi.getById(item.variable.id)
                .then(data => {
                    setReading(data.data);
                })
        }

        let intervalId = setInterval(updateValue, refreshInterval);
        window.console.log("Запуск обновления компонента", item)
        updateValue();
        return () => {
            window.console.log("Остановка обновления компонента", item)
            clearInterval(intervalId);
        };
    }, [refreshInterval, item]);

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        }}>
            <div style={{ fontSize: '5.2em' }}> {String(reading?.value)}</div>
            <div style={{}}>{reading && (convertTime(reading.dateTime))}</div>
        </div >
    )

}