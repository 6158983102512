
export enum DeviceProtocolType {
    TCP_MSGPACK = "TCP_MSGPACK",
    HTTPS = "HTTPS"
}

export interface DeviceDto {
    id: string;
    name: string;
    type: number;
    description: string;
    isActive: boolean;
    apiKey: string;
    lastActiveTime: string;
    createTime: string;
    protocolType: DeviceProtocolType;
}


export interface DeviceCreateDto {
    name: string;
    protocolType: DeviceProtocolType;
    description: string;
    isActive: boolean;
    apiKey: string;
}


export interface DeviceUpdateDto {
    id: string;
    name: string;
    description: string;
    isActive: boolean;
    apiKey: string;
    protocolType: DeviceProtocolType;
}

