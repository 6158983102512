export enum Status {
    GROWING = "GROWING",
    INCUBATION = "INCUBATION"
}


export interface TransferBlockDto {
    chamberName: string,
    rowName: string,
    placeNumber: Number
}

export interface AccountingDto {
    currentCount: Number,
    transferCount: Number,
    transfers: TransferBlockDto[]
}


export interface BlockPositionInfo {
    blockId: string,
    chamberName: string,
    rowName: string,
    placeNumber: string,
    strainName: string,
    daysTotal: number,
    daysPassed: number

}