import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { AuthApi } from '../../../api';

import { login } from '../../../state/userReducer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = { username: '', password: '' };
  }

  componentDidMount() {///todo проверять залогинен уже?

  }

  render() {
    return (

      <div className="col-sm-2 offset-sm-5 text-center justify-content-center">
        {/* <h2 className="display-4">Вход</h2> */}
        <form className="">
          <div className="form-group">
            <label login="login">Логин</label>
            <input name="login" className="form-control mr-sm-2" type="text" placeholder="Логин" autoComplete="username" onChange={this.handleLoginChange} />
            {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
          </div>
          <div className="form-group ">
            <label login="password">Пароль</label>
            <input name="password" className="form-control mr-sm-2" type="password" placeholder="Пароль" autoComplete="current-password" onChange={this.handlePasswordChange} />
          </div>
          
          <div className="form-group">
            <button className="btn btn-primary mr-sm-2 col-6" type="button" onClick={this.onLogin}>Вход</button>
          </div>

        </form>
        <div className=" ">
          <Link className="" to="/register"  >Зарегистрироваться</Link> <span> / </span>
          <Link className="" to="/requestNewPassword" >Забыли пароль?</Link>
          <a href='https://play.google.com/store/apps/details?id=com.acumendev.gribcontrol.terminal&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img height='80' width='180' alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></a>
                
        </div>
      </div>
    );
  }

  handleLoginChange = (event) => {
    this.setState({ username: event.target.value.trim() });
  }

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value.trim() });
  }

  onLogin = async () => {
    //console.log('onLogin', this.state)
    await AuthApi.login(this.state)
      .then((result) => {

  //   debugger

        for (const [key, val] of Object.entries(result.data)) {
          localStorage.setItem(key, val);
        }

        this.props.dispatch(login(result))
        this.props.history.push("/dashboard")
      }, (result) => {

      })
  }
}

const mapStateToProps = function (store) {
  return {

  };
};


export default withRouter(connect(mapStateToProps)(Login))