import HttpApi, { BaseHttpResponse } from './HttpApi';
import { AccountingDto,BlockPositionInfo ,Status} from './types/accounting';

export class AccountingApi extends HttpApi {

    async getTransferByStatus(status: Status) {
        return await this.get<AccountingDto, BaseHttpResponse>('/transfer/' + status);
    }

    async getByStatus(status: Status) {
        return await this.get<BlockPositionInfo, BaseHttpResponse>('/' + status);
    }

    async getBlockCodes(count: Number) {
        return await this.getPostBinary('/codes', {  }, {params: { count: count }, responseType: 'blob' });
    }
}
export default new AccountingApi('/api/accounting');