import React, { FC, useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { DashboardItemApi } from 'api'
import { DashboardItem, DashboardItemType } from 'api/types/dashboard';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import { AvailableVariables } from '../../../components/availableVariables/availableVariables'
import { AvailableTypes } from '../../components/availableTypes/availableTypes'
import { DashboardItemEditChart } from './components/dashboardItemEditChart/dashboardItemEditChart'
import DateTimeRangePicker from '../../../components/dateTimeRangePicker/dateTimeRangePicker'
import {DashboardItemEditCounter} from '../dashboardItemEdit/components/dashboardItemEditCounter/dashboardItemEditCounter'

export const DashboardItemEdit: FC = () => {
    let params = useParams<{ id: string }>();
    const [item, setItem] = useState<DashboardItem>({ id: "", name: "", type: DashboardItemType.BLANK });

    const [dateFrom, setDateFrom] = useState<Date>();
    const [dateTo, setDateTo] = useState<Date>();
    useEffect(() => {
        DashboardItemApi.getById(params.id)
            .then(data => {
                setItem(data.data);
            })
    }, [params.id]);

    const onSave = () => {
        // debugger
        DashboardItemApi.update(item)
            .then(data => {
                //setItem(data.data);
            })
    };

    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setItem(() => ({ ...item, [name]: value }));
    };

    return (
        <Container maxWidth={false}>
            <div className="first-row">
                {/* <h2 style={{ display: 'inline-block', with: '200px' }}>{dashbord.name}</h2> */}
                <TextField name="name" required id="standard-required" style={{ width: "200px" }} value={item.name} defaultValue={item.name} onChange={handleChange} />
                <div
                    style={{
                        display: 'flex',
                        float: "right",
                        justifyContent: "flex-end",
                    }}
                >
                    <DateTimeRangePicker
                        onChange={(ewDateFrom, newDateTo) => {
                            setDateFrom(ewDateFrom);
                            setDateTo(newDateTo);
                        }}
                      //  refreshInterval={1000} 
                      />
                    <IconButton aria-label="upload picture" component="span" onClick={() => { onSave() }}>
                        <SaveIcon />
                    </IconButton>

                </div>
            </div>

            <div style={{
                textAlign: "center",
                width: "75%",
                height: "400px",
                justifyContent: "center",
                display: "flex",
                border: "solid"
            }}>

                {item.type === DashboardItemType.COUNTER && <DashboardItemEditCounter item={item} refreshInterval={5000} />}
                {item.type === DashboardItemType.CHART && <DashboardItemEditChart item={item} refreshInterval={5000} from={dateFrom} to={dateTo} />}

            </div>
            <br />

            <div><AvailableTypes current={item.type} onChangeItem={(newValue) => setItem({ ...item, type: newValue })} /> </div>
            <br />

            <div> <AvailableVariables current={item.variable} onChangeItem={(newValue) => setItem({ ...item, variable: newValue })} /></div>

        </Container>)
}