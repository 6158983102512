import { FC } from 'react';
import { Link } from 'react-router-dom';
import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';


interface Props {
    id: string;
    name: string;
    onDelete: (a: string) => void;
}

export const DashboardItemName: FC<Props> = ({
    id,
    name,
    onDelete
}) => {


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<div>
        <div className="card-title text-sm-center">
            <span>{name}</span>
            <IconButton
                size="small"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
        </div>

        <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
        >
            <MenuItem onClick={handleClose}><Link style={{ textDecoration: 'none', color: 'inherit', }} to={`/dashboard/item/edit/${id}`}> Редактировать</Link> </MenuItem>
            <MenuItem onClick={() => { onDelete(id); handleClose(); }}>Удалить</MenuItem>
        </Menu>
    </div>
    );

}