import React, { Component } from 'react'
import { ChamberApi } from '../../../api';
import  { TrashIcon } from '@primer/octicons-react'

export default class ChamberEdit extends Component {
    constructor(props) {
        super(props);
        const { chamberId } = this.props.match.params

        this.state = {
            chamber: {
                id: chamberId,
                name: "",
                description: "",
                rows: []
            },

            defaultNameRow: "A",
            numberPlaces: 1
        };
    }

    componentDidMount() {
        ChamberApi.getById(this.state.chamber.id)
            .then((result) => {
                this.setState(state => ({
                    chamber: result
                }));
            });
    }

    handleAddRow = () => {
        console.log('handleAddRow', this.state)

        var newRows = this.state.chamber.rows.concat({ name: this.state.defaultNameRow, numberPlaces: this.state.numberPlaces })
        var newChamber = Object.assign({}, this.state.chamber);
        newChamber.rows = newRows

        this.setState({
            chamber: newChamber
        });
    }

    handleRemoveRow = (index) => {
        console.log('handleRemoveRow', index)
        var rows = this.state.chamber.rows
        var newRows = rows.slice()
        newRows.splice(index, 1)

        var newChamber = Object.assign({}, this.state.chamber);
        newChamber.rows = newRows

        this.setState( {
            chamber : newChamber
        });

    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var chamber = Object.assign({}, this.state.chamber);
        chamber[name] = value

        this.setState(state => {
            state.chamber = chamber
        });
    }


    handleInputRowChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(state => {
            state[name] = value
        });
    }


    handleUpdate = async () => {

        console.log('onCreate', this.state.chamber)
        await ChamberApi.update(this.state.chamber)
            .then((result) => {
                console.log('response', result)
                this.props.history.push("/chamber")
            }, (result) => {
                console.log('onCreate error', result)
            })
    }

    render() {
        const { chamber } = this.state
        return (
            <div className="container-fluid m-3">

                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-1 col-form-label">Название</label>
                    <div className="col-sm-3">
                        <input type="text" className="form-control" name="name" id="inputName" defaultValue={chamber.name} placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputDescription" className="col-sm-1 col-form-label">Описание</label>
                    <div className="col-sm-3">
                        <input type="text" name="description" className="form-control" id="inputDescription" defaultValue={chamber.description} placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row ">
                    <label className="col-sm-1 col-form-label">Места в камере</label>
                    <div className="col-sm-3">
                        <div className="form-group row">
                            <label htmlFor="inputDescription" className="col-sm-3 col-form-label">Название ряда</label>
                            <div className="col-sm-3">

                                <input type="text" maxLength="2" name="defaultNameRow" pattern="[A-Z]{1,2}" className="form-control" id="inputDescription" defaultValue={this.state.defaultNameRow} onChange={this.handleInputRowChange} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputDescription" className="col-sm-3 col-form-label">Мест в ряду</label>
                            <div className="col-sm-3">

                                <input type="number" min="1" name="numberPlaces" defaultValue={this.state.numberPlaces} className="form-control" id="inputDescription" placeholder="" onChange={this.handleInputRowChange} />
                            </div>
                        </div>

                        <div className="form-group ">
                            <div className="col-sm-4">
                                <button type="button" className="btn btn-primary" onClick={this.handleAddRow}>Добавить ряд</button>
                            </div>
                        </div>

                        <table className="table table-hover table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">Название</th>
                                    <th scope="col">Мест</th>
                                    <th scope="col">Действие</th>
                                </tr>
                            </thead>
                            <tbody>
                                {chamber.rows.map((row, index) =>
                                    <tr className="nodeData" key={index}>
                                        <th scope="row">{row.name}</th>
                                        <td>{row.numberPlaces}</td>
                                        <td>
                                            <div onClick={() => this.handleRemoveRow(index)}> <TrashIcon size='medium' /></div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-10">
                        <button type="button" className="btn btn-success" onClick={this.handleUpdate}>Сохранить</button>
                    </div>
                </div>
            </div>
        );
    }
}