import { FC, useState, useEffect } from 'react';
import 'flatpickr/dist/themes/material_green.css'
import Flatpickr from 'react-flatpickr'
import { Russian } from "flatpickr/dist/l10n/ru.js"
//import { Button } from '@material-ui/core';

interface Props {
    from?: Date;
    to?: Date;
    onChange: (newDateFrom, newDateTo) => void;
    additionalButton?: any;
}

const DateTimeRangePicker: FC<Props> = ({
    from,
    to,
    onChange,
    additionalButton
}) => {


    const [dateFrom, setDateFrom] = useState<Date>(() => {
        if (from != null) {
            return from;
        }

        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday;

    });
    const [dateTo, setDateTo] = useState<Date>(() => {
        if (to != null) {
            return to;
        } 
        return new Date()
    });


    useEffect(() => {
        onChange(dateFrom, dateTo)
    });

    // const {  onClickButton, additionalButton } = props




/*     const onClickButton = () => {
        console.info(`You clicked `);
        onChange(dateFrom, dateTo)
    }; */

    // debugger
    return (
        <div className="container">
            <div className='row mt-2 '>
                <div className='col'>
                    <Flatpickr data-enable-time
                        className="form-control m-1"
                        value={dateFrom}
                        options={{
                            time_24hr: true,
                            //  maxDate: this.state.dateFrom,
                            enableTime: true,
                            locale: Russian,
                            // plugins: [confirmDatePlugin({
                            //     confirmText: "OK"
                            // })]
                        }}
                        onChange={value => { setDateFrom(value[0]) }}
                    />
                </div>
                <div className='col'>
                    <Flatpickr
                        className="form-control m-1"
                        data-enable-time
                        value={dateTo}
                        options={{
                            time_24hr: true,
                            // maxDate: this.state.dateTo,
                            enableTime: true,
                            locale: Russian,
                            // plugins: [confirmDatePlugin({
                            //     confirmText: "OK"
                            // })]
                        }}
                        onChange={value => { setDateTo(value[0]) }} />
                </div>
                <div className='col m-1  d-flex justify-content-start'>

                    {/* <Button variant="contained" color="primary" onClick={onClickButton}>
                        Показать
                    </Button> */}
                    {additionalButton}
                </div>
            </div>
        </div>

    );
}
export default DateTimeRangePicker;