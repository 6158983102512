import { FC, useState } from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { DeviceValueMapDto } from 'api/types/deviceValueMap';
import TextField from '@material-ui/core/TextField';
import { AvailableVariableTypes } from 'modules/components/AvailableVariableTypes/AvailableVariableTypes'

import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { AvailableVariables } from 'modules/components/availableVariablesNew/availableVariables';
import IconButton from '@material-ui/core/IconButton';

interface Props {
    deviceValueMapDto: DeviceValueMapDto;
    onSave: (updateItem: DeviceValueMapDto) => void;
    onDelete: (id: string) => void;
}

export const DeviceMappingTableRow: FC<Props> = ({
    deviceValueMapDto,
    onSave,
    onDelete
}) => {

    let [item, setItem] = useState<DeviceValueMapDto>(deviceValueMapDto);


    const onHandleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setItem(() => ({ ...item, [name]: value }));
    };


    return (

        <TableRow >
            <TableCell component="th" scope="row" style={{ width: 160 }}>
                <TextField name="name" label="Имя" value={item.name} onChange={onHandleChange} />
            </TableCell>
            <TableCell  >
                < AvailableVariableTypes current={item.type} onChangeItem={(newValue) => { newValue && setItem({ ...item, "type": newValue }) }} />
            </TableCell>
            <TableCell  >
                <TextField name="key" label="Ключ" value={item.key} onChange={onHandleChange} />
            </TableCell>
            <TableCell  >

                <AvailableVariables variableId={item.variableId} onChangeItem={(newValue) => { newValue && setItem({ ...item, "variableId": newValue.id }) }} />
            </TableCell>
            <TableCell  >
                {item.directionType}
            </TableCell>
            <TableCell  >

                <TextField name="description" label="Описание" value={item.description == null ? "" : item.description} onChange={onHandleChange} />
            </TableCell>
            <TableCell align="right">

                <IconButton aria-label="upload picture" onClick={() => { onSave(item) }}  >
                    <SaveIcon />
                </IconButton>
                <IconButton aria-label="upload picture" onClick={() => { onDelete(item.id) }}   >
                    <DeleteIcon />
                </IconButton>

            </TableCell>
        </TableRow >

    );
}


