import HttpApi, { BaseHttpResponse } from './HttpApi';

import { DashboardDto } from './types/dashboard';
import { AvalibleItem } from './types/availableItem'

export class DashboardApi extends HttpApi {
    async getDashboard() {
        return await this.get<DashboardDto, BaseHttpResponse>('/', {});
    }
    async getValue(dashboardId: number) {
        return await this.get<DashboardDto, BaseHttpResponse>('/values/' + dashboardId);
    }
    async getAvalibleItems() {
        return await this.get<AvalibleItem[], BaseHttpResponse>('items');
    }

    async update(dashboard: DashboardDto) {
        return await this.put<DashboardDto, BaseHttpResponse>('/', dashboard);
    }
}

export default new DashboardApi('/api/dashboard');
