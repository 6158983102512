import HttpApi, { BaseHttpResponse } from './HttpApi';

import { DashboardItem, DashboardItemCreateDto } from './types/dashboard';

export class DashboardItemApi extends HttpApi {
    async getById(id: string) {
        return await this.get<DashboardItem, BaseHttpResponse>('/' + id);
    }
    async create(dashboardItem: DashboardItemCreateDto) {
        return await this.post<DashboardItem, BaseHttpResponse>('/', dashboardItem);
    }
    async update(dashboardItem: DashboardItem) {
        return await this.put<DashboardItem, BaseHttpResponse>('/', dashboardItem);
    }
}

export default new DashboardItemApi('/api/dashboard/item');