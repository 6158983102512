import { FC, useState, useEffect } from 'react';
import Draggable from "react-draggable";
import { DashboardItem } from 'api/types/dashboard';
import { Reading } from 'api/types/variable';
import { VariableValueApi } from 'api';
import { DashboardItemName } from '../dashboardItemName/dashboardItemName'
import { convertTime } from 'utils/timeUtils'
interface Props {
    item: DashboardItem;
    refreshInterval: number;
    onDelete: (a: string) => void;
}

export const DashboardItemCounter: FC<Props> = ({
    item,
    refreshInterval,
    onDelete,
}) => {
    const [reading, setReading] = useState<Reading>({ value: 0, dateTime: "" });

    useEffect(() => {
        const updateValue = () => {
            window.console.log("Запрос значений для", item)
            item.variable && VariableValueApi.getById(item.variable.id)
                .then(data => {
                    setReading(data.data);
                })
        }
        if (refreshInterval <= 0) {
            return
        }
        let intervalId = setInterval(updateValue, refreshInterval);
        window.console.log("Запуск обновления компонента", item)
        updateValue();
        return () => {
            window.console.log("Остановка обновления компонента", item)
            clearInterval(intervalId);
        };
    }, [refreshInterval, item]);

    return (
        <Draggable>
            <div className="card" style={{ width: "15rem", height: "15rem", margin: "1rem" }} >
                <div className="card-body">
                    <DashboardItemName id={item.id} name={item.name} onDelete={onDelete} />
                    {
                        <div className="card-text text-center"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}>

                            <div style={{ fontSize: '5em' }}> {String(reading?.value)}</div>
                            <div style={{}}>{reading && (convertTime(reading.dateTime))}</div>
                        </div>
                    }
                </div>
            </div>
        </Draggable>
    )
}