import React, { Component } from 'react'
import { UserApi } from '../../../api';

export default class SetNewPassword extends Component {

    constructor(props) {
        super(props);

        const { linkId } = this.props.match.params
        this.state = {
            linkId:linkId,
            password: "",
            passwordRepeat: "",
            isOk: false
        };
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleButton = async () => {
        
        if (this.state.password !== this.state.passwordRepeat) {
            return
        }
        var setNewPassword = {
            linkId: this.state.linkId,
            password: this.state.password
        }
        console.log('', setNewPassword)
        await UserApi.setNewPassword(setNewPassword)
            .then((result) => {
                console.log('response', result)
                this.setState({
                    isOk: true
                })
            }, (result) => {
                console.log('error', result)
            })
    }

    render() {

        if (this.state.isOk) {
            return (
                <div className="container-fluid m-3">
                    Новый пароль установлен.
                    <br />
                    Можете войти в систему используя новый пароль.
                </div>
            );
        }

        return (
            <div className="container-fluid m-3">
                <form>
                <div className="form-group row">
                    <label htmlFor="password" className="col-sm-1 col-form-label">Введите новый пароль</label>
                    <div className="col-sm-3">
                        <input type="password"  name="password" className="form-control" id="password" autoComplete="new-password" placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="passwordRepeat" className="col-sm-1 col-form-label">Пароль еще раз</label>
                    <div className="col-sm-3">
                        <input type="password"  name="passwordRepeat" className="form-control" id="passwordRepeat" autoComplete="new-password" placeholder=""  onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-10">
                        <button type="button" className="btn btn-success" onClick={this.handleButton}>Установить пароль</button>
                    </div>
                </div>
                </form>
            </div>
        );
    }
}