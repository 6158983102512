import HttpApi, { BaseHttpResponse } from './HttpApi';
import { User, UserUpdate,UserCreate, UpdatePasswordDto } from './types/user';

export class UserApi extends HttpApi {
  async getMe() {
    const response = await this.get<User, BaseHttpResponse>('/me');
    return response;
  }

  async register(userCreate: UserCreate) {
    return await this.post<User, BaseHttpResponse>('/create', userCreate);
  }

  async activate(token) {
    return await this.post<User, BaseHttpResponse>('/activate', token);
  }

  async requestNewPassword(email: string) {
    return await this.post<User, BaseHttpResponse>('/requestNewPassword', email);
  }

  async setNewPassword(updateNewPassword: UpdatePasswordDto) {
    return await this.post<User, BaseHttpResponse>('/setNewPassword', updateNewPassword);
  }

  
  async update(userUpdate: UserUpdate) {
    return await this.patch<User, BaseHttpResponse>('/', userUpdate);
  }

  async resendActivateLink(email: string) {
    return await this.post<User, BaseHttpResponse>('/resendActivateLink', email);
  }


}
export default new UserApi('/api/user');