import HttpApi, { BaseHttpResponse } from './HttpApi';

import { Reading } from './types/variable';

export class VariableValueApi extends HttpApi {
    async getById(id: string) {
        return await this.get<Reading, BaseHttpResponse>('/' + id);
    }

    async getRange(id: string, from: Date, to: Date) {
       
        return await this.get<Reading, BaseHttpResponse>('/' + id + '/list', { params: { from: from.toISOString(), to: to.toISOString() }});
    }

}

export default new VariableValueApi('/api/variable/value');