import { Container, makeStyles } from '@material-ui/core';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TablePaginationActions } from 'modules/components/TablePaginationActions/TablePaginationActions'
import { FC, useEffect, useState } from 'react';

import { ReportApi } from '../../../api'
import { dowloadFile } from '../../../utils/downloadFileUtils'
import { Report } from 'api/types/report';
import DateTimeRangePicker from 'modules/components/dateTimeRangePicker/dateTimeRangePicker';
import { Button } from '@material-ui/core';

const useStyles2 = makeStyles({
    table: {
        minWidth: 500
    }
});

const ReportList: FC = () => {

    const classes = useStyles2();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [items, setItems] = useState<Report[]>([]);
    const [totalElements, setTotalElements] = useState(0);
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);


    const [dateFrom, setDateFrom] = useState<Date>(() => {
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday;

    });
    const [dateTo, setDateTo] = useState<Date>(() => {
        return new Date()
    });


    useEffect(() => {
        function getAll() {
            ReportApi.getRange(dateFrom.toISOString(), dateTo.toISOString(), page, rowsPerPage)
                .then((result) => {
                    setItems(result.data.items);
                    setTotalElements(result.data.totalElements)
                });
        }
        getAll();
    }, [page, rowsPerPage, dateFrom, dateTo]);


    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const download = () => {
        ReportApi.getCSV(dateFrom.toISOString(), dateTo.toISOString())
            .then((response) => {
                dowloadFile(response)
            });
    }

    return (
        <Container maxWidth={false} >
            <div className="">
                <h2 style={{ display: 'inline-block', width: '200px' }}>Отчет</h2>

                <div
                    style={{
                        display: 'flex',
                        float: "right",
                        justifyContent: "flex-end",
                        width: '900px'

                    }}>
                    {/* <DateTimeRangePicker /> */}
                    <DateTimeRangePicker

                        onChange={((newDateFrom, newDateTo) => {
                            setDateFrom(newDateFrom);
                            setDateTo(newDateTo);
                        })}

                        additionalButton={
                            <Button variant="contained"  onClick={download} style={{marginLeft: '10px'}} >
                                Скачать CSV
                            </Button>

                        }
                    />

                </div>
            </div>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>

                            <TableCell>Партия</TableCell>
                            <TableCell>Штамм</TableCell>
                            <TableCell>Количество блоков</TableCell>
                            <TableCell>Старт инкубации</TableCell>
                            <TableCell>Коментарий инкубации</TableCell>
                            <TableCell>Старт плодоношения</TableCell>
                            <TableCell>Комментарий плодоношения</TableCell>
                            <TableCell>Старт утилизации</TableCell>
                            <TableCell>Количество бракованных блоков</TableCell>
                            <TableCell>Комментарий утилизации</TableCell>
                            <TableCell>Причина утилизации</TableCell>
                            <TableCell>Всего собранно гриба</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            items.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">{row.name} </TableCell>
                                    <TableCell>{row.strainName}</TableCell>
                                    <TableCell>{row.count}</TableCell>
                                    <TableCell>{row.incubationStart} </TableCell>
                                    <TableCell>{row.incubationComment} </TableCell>
                                    <TableCell>{row.growingStart} </TableCell>
                                    <TableCell>{row.growingComment} </TableCell>
                                    <TableCell>{row.utilisationStart} </TableCell>
                                    <TableCell>{row.marriagesCount} </TableCell>
                                    <TableCell>{row.utilisationComment} </TableCell>
                                    <TableCell>{row.utilisationReason} </TableCell>
                                    <TableCell>{row.totalCollectedWeight} </TableCell>
                                </TableRow>
                            ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[15, 30, 100, { label: "All", value: -1 }]}
                                colSpan={3}
                                count={totalElements}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { "aria-label": "Записй на странице" },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Container >
    );
}
export default ReportList;