import { FC, useState, useEffect } from 'react';
import { DashboardItem } from 'api/types/dashboard';
import { Reading, VariableType } from 'api/types/variable';
import { VariableValueApi } from 'api';


import { convertTime } from 'utils/timeUtils'
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';



interface Props {
    item: DashboardItem;
    refreshInterval: number;
    from?: Date;
    to?: Date;
}

export const DashboardItemEditChart: FC<Props> = ({
    item,
    refreshInterval,
    from,
    to
}) => {

    const [readings, setReadings] = useState<Reading[]>();

    //  const [intervalId, setIntervalId] = useState<any>();


    useEffect(() => {
        const updateValue = () => {
            window.console.log("Запрос значений для", item)
            item.variable && from && to && VariableValueApi.getRange(item.variable.id, from, to)
                .then((result) => {
                    result.data.forEach(itemR => {
                        itemR.dateTime = convertTime(itemR.dateTime);
                        if (item.variable?.valueType === VariableType.BOOL) {
                            itemR.value = itemR.value ? 1 : 0;
                        }
                    })

                    setReadings(result.data);
                })
        }


        let intervalId = setInterval(updateValue, refreshInterval);
        window.console.log("Запуск обновления компонента", item)
        updateValue();
        return () => {
            window.console.log("Остановка обновления компонента", item)
            clearInterval(intervalId);
        };
    }, [refreshInterval, item, from, to]);



    return (

        <ResponsiveContainer>
            <LineChart
                className=" "

                data={readings}
                margin={{
                    top: 5, right: 20, left: 5, bottom: 5,
                }}                    >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dateTime" />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}
                <Line type="linear" dataKey="value" name="Значение" stroke="red" activeDot={{ r: 8 }} />

            </LineChart>
        </ResponsiveContainer>

    )

}