import HttpApi, { BaseHttpResponse } from './HttpApi';

import { VariableShortDto, VariableDto, VariableCreateDto } from './types/variable';
import { Pagination } from './types/pagination';

export class VariableValueApi extends HttpApi {
    async getShortAll() {
        return await this.get<VariableShortDto, BaseHttpResponse>('/short');
    }


    async getAll(page: number, rowsPerPage: number) {
        return await this.get<Pagination<VariableDto>, BaseHttpResponse>('/', { params: { page: page, limit: rowsPerPage } });
    }

    async getById(id: string) {
        return await this.get<VariableDto, BaseHttpResponse>('/' + id);
    }

    async add(variable: VariableCreateDto) {
        return await this.post<VariableDto, BaseHttpResponse>('/', variable);
    }

    async update(variable: VariableDto) {
        return await this.put<VariableDto, BaseHttpResponse>('/', variable);
    }


}

export default new VariableValueApi('/api/variable');