import React, { Component } from 'react'
import { UserApi } from '../../../api'

export default class UserActivate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activate: false
        };
    }

    componentDidMount() {
        const { token } = this.props.match.params

        var activateToken = { token: token }


        UserApi.activate(activateToken)
            .then((result) => {

                this.setState(state => ({
                    activate: true
                }));
            });
    }

    render() {

        return (
            <div className="row m-3">
                <p>Активация пользователя </p><br/>
                {this.state.activate && <p> Завершена</p>}
            </div>
        );
    }
}