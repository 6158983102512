import React, { Component } from 'react'
import { ChamberApi } from '../../../api'
import ChamberChart from '../components/chamberChart/chamberChart'
//import { API_URL } from '../../../api/HttpApi'
//import { convertTime } from '../../../utils/timeUtils'
import { dowloadFile } from '../../../utils/downloadFileUtils'
export default class ChamberDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            load: false,
            chamber: {},
            counter: null

        };
    }

    componentDidMount() {
        const { chamberId } = this.props.match.params
        this.fetchChamber()
        ChamberApi.getCounter(chamberId)
            .then((result) => {
                this.setState(state => ({
                    counter: result.data.value

                }));
            });
    }

    onChangeCounter = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState(state => ({
            counter: value
        }));
    }

    saveCounterHandler = () => {
        const { chamberId } = this.props.match.params
        var newCounter = { count: this.state.counter }
        ChamberApi.setCounter(chamberId, newCounter)
            .then((result) => {

            });
    }

    fetchChamber = () => {
        const { chamberId } = this.props.match.params
        ChamberApi.getById(chamberId)
            .then((result) => {

                this.setState(state => ({
                    chamber: result.data,
                    load: true
                }));
            });
    }

    downloadPdf = () => {
        const { chamberId } = this.props.match.params
        ChamberApi.getPdf(chamberId)
            .then((response) => {
                dowloadFile(response)
            });
    }

    render() {

        if (!this.state.load) {
            return <div>загрузка </div>
        }

        const { chamber, counter } = this.state;

        return (

            <div className="ml-5 mt-3 mr-3">

                <div className='container text-center '>
                    <h3>   {chamber.name} </h3>
                </div>

                <div className="">
                    <div className='row mt-2' >
                        <div className='col-2-sm'>

                            <div className="row">
                                <div className="col-3-sm">
                                  
                                    {/* <div className="row m-2">
                                        <button type="button" className="btn btn-primary" onClick={this.downloadPdf}>Скачать метки мест</button>
                                    </div> */}

                                    <div className="row mt-2">
                                        <div className="col-sm">Текущие количесво блоков</div>
                                        <div className="col-2-sm"><b>{counter}</b></div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-sm">Описание</div>
                                        <div className="col-2-sm">{chamber.description}</div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="col-sm d-flex justify-content-center">
                            {<ChamberChart chamberId={chamber.id} />}
                        </div>
                    </div>

                </div>
                <div className='row'></div>
            </div>



        );
    }
}