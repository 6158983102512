export enum VariableType {
    INT ='INT',
    BIGINT='BIGINT',
    FLOAT='FLOAT',
    DOUBLE='DOUBLE',
    BOOL='BOOL'
}

export interface Reading {
    value: any;
    dateTime: string;
}

export interface VariableShortDto {
    id: string;
    name: string;
    valueType: VariableType;
}

export interface VariableDto {
    id: string;
    name: string;
    valueType: VariableType;
    description: string;
    isPersist: boolean;
    isActive: boolean;
    isDeleted: boolean;
}

export interface VariableCreateDto {
    name: string;
    valueType: VariableType;
    description: string;
    isPersist: boolean;
    isActive: boolean;
    isDeleted: boolean;
}