import HttpApi, { BaseHttpResponse } from './HttpApi';

import { AuthRequest,AuthResponse } from './types/auth';

 class AuthApi extends HttpApi {
  async login(request: AuthRequest) {
    const response = await this.post<AuthResponse,BaseHttpResponse<AuthResponse>>('/login', request);
    return response;
  }


  logout() {
    return this.http.post<void>('/logout');
  }
}

export default new AuthApi('/api/auth');
