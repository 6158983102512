import { FC, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStyles } from './styles';
import { DashboardItemType } from 'api/types/dashboard';


interface Props {
    current: DashboardItemType | null | undefined,
    onChangeItem: any
}

export const AvailableTypes: FC<Props> = ({
    current,
    onChangeItem
}) => {
    const [items] = useState<DashboardItemType[]>([DashboardItemType.BLANK, DashboardItemType.COUNTER, DashboardItemType.CHART]);
    const [value, setValue] = useState<DashboardItemType | null | undefined>(current);

    useEffect(() => {
        const sele = items.find(element => element === current);
        sele && setValue(sele);
    }, [current, items]);

    const classes = useStyles();

    return (
        <Autocomplete
            className={classes.comboBox}
            id="comboBox"
            options={items}
            getOptionLabel={(option) => option.toString()}
            value={value ? value : null}
            getOptionSelected={(option, value) => {

                return option === value
            }}

            onChange={(_event: any, newValue: DashboardItemType | null) => {
                setValue(newValue);
                onChangeItem(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Тип" variant="outlined" />}
        />
    );

}