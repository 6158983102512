import HttpApi, { BaseHttpResponse } from './HttpApi';

import { DeviceValueMapDto,DeviceValueMapCreateDto } from './types/deviceValueMap';
import { Pagination } from './types/pagination';


export class DeviceApi extends HttpApi {

    async getAll(deviceId: string, page: number, rowsPerPage: number) {
        return await this.get<Pagination<DeviceValueMapDto>, BaseHttpResponse>('/', { params: { deviceId: deviceId, page: page, limit: rowsPerPage } });
    }

    async getById(id: string) {
        return await this.get<DeviceValueMapDto, BaseHttpResponse>('/' + id);
    }

    async add(device: DeviceValueMapCreateDto) {
        return await this.post<DeviceValueMapDto, BaseHttpResponse>('/', device);
    }

    async update(device: DeviceValueMapDto) {
        return await this.put<DeviceValueMapDto, BaseHttpResponse>('/', device);
    }

}
export default new DeviceApi('/api/device/value-mapping');