import { FC, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStyles } from './styles';
import {VariableDto} from 'api/types/variable'
import {VariableApi} from 'api'



interface Props {
    current: VariableDto | null | undefined,
    onChangeItem: any
}

export const AvailableVariables: FC<Props> = ({
    current,
    onChangeItem
}) => {
    const [load, setLoad] = useState<Boolean>(false);
    const [items, setItems] = useState<VariableDto[]>([]);
    const [value, setValue] = useState<VariableDto | null | undefined>(current);


    useEffect(() => {
        VariableApi.getShortAll()
            .then((result) => {
                setItems(result.data);
                const sele = result.data.find(element => element.id === current?.id);
                sele && setValue(sele);
                setLoad(true);
            });
    }, [current]);

    const classes = useStyles();

    if (load === false) {
        return (<div>Загрузка</div>);
    }


    return (
        <Autocomplete
            className={classes.comboBox}
            id="comboBox"
            options={items}
            getOptionLabel={(option) => option.name}
            value={value ? value : null}
            getOptionSelected={(option, value) => {

                return option.id === value.id
            }}


            onChange={(_event: any, newValue: VariableDto | null) => {
                setValue(newValue);
                onChangeItem(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Источник" variant="outlined" />}
        />
    );

}
