import { FC,  useState } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


const selectOptions = [
    { name: "off", value: -1 },
    { name: "5s", value: 5 },
    { name: "10s", value: 10 },
    { name: "30s", value: 30 },
    { name: "1m", value: 60 },
    { name: "5m", value: 5 * 60 },
    { name: "15m", value: 15 * 60 },
    { name: "30m", value: 30 * 60 },
    { name: "1h", value: 60 * 60 },
    { name: "2h", value: 60 * 60 * 2 },
    { name: "1d", value: 60 * 60 * 24 },
];

interface Props {
    onRefreshEvent: () => void;
    onNewRefreshIntervaEvent: (newInterval:number) => void;
}

export const RefreshControl: FC<Props> = ({
    onRefreshEvent,
    onNewRefreshIntervaEvent
}) => {

    const [curentSelectOption, setCurentSelectOption] = useState<number>(5);

    // useEffect(() => {
    //   /*   var intervalId;
    //     clearInterval(intervalId);
    //     intervalId = setInterval(onRefreshEvent, curentSelectOption * 1000);
    //     window.console.log("Запуск обновления компонента ")
    // */  
    //   onRefreshEvent(curentSelectOption);
    //   /*   return () => {
    //         window.console.log('Остановка обновления компонента ')
    //         clearInterval(intervalId);
    //     }; */
    // }, [curentSelectOption, onRefreshEvent]);

    const handleClick = () => {
        console.info(`You clicked `);
        onRefreshEvent();
    };

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let selectValue = event.target.value as number;
        console.info(`You handleChange ${selectValue}`);
        setCurentSelectOption(selectValue);
        onNewRefreshIntervaEvent(selectValue*1000);
    };

    return (

        <ButtonGroup  >
            <IconButton onClick={handleClick}>
                <AutorenewIcon />
            </IconButton>

            <Select
           
                variant="standard"
                onChange={handleChange}
                value={curentSelectOption}
            >
                {
                    selectOptions.map((item, i) => {
                        return <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                    })
                }
            </Select>
        </ButtonGroup>
    )
};