import HttpApi, { BaseHttpResponse } from './HttpApi';
import { ChamberCounterValue } from './types/chamberReading';

export class ChamberReadingsApi extends HttpApi {
    async getRange(id, from, to) {
        return await this.get<ChamberCounterValue[], BaseHttpResponse>('/' + id, { params: { from: from, to: to } });
    }


}
export default new ChamberReadingsApi('/api/readings/chamber');