import React, { Component } from 'react'
import { StrainApi } from '../../../api';

export default class StrainCreate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newStrain: {
                name: "",
                incubationPeriod: "",
                growingPeriod: "",
                description: ""
            },
        };
    }

    componentDidMount() {
        const { strainId } = this.props.match.params

              if (strainId !== undefined) {


            StrainApi.getById(strainId)
                .then((result) => {
                    var now = new Date()
                    result.data.name = result.data.name + " " + now.toLocaleDateString() + " " + now.toLocaleTimeString()

                    this.setState({
                        newStrain: result.data
                    })
                });
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var newStrain = Object.assign({}, this.state.newStrain);
        newStrain[name] = value

        this.setState(state => {
            state.newStrain = newStrain
        });
    }

    handleCreate = async () => {
        console.log('onCreate', this.state.newStrain)
        await StrainApi.create(this.state.newStrain)
            .then((result) => {
                console.log('response', result)
                this.props.history.push("/strain")
            }, (result) => {
                console.log('onCreate error', result)
            })
    }

    render() {

        const { newStrain } = this.state

        return (
            <div className="container-fluid  m-3">

                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-1 col-form-label">Название</label>
                    <div className="col-sm-3">
                        <input type="text" className="form-control" name="name" id="inputName" defaultValue={newStrain.name} placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputIncubationPeriod" className="col-sm-1 col-form-label">Сколько дней инкубации</label>
                    <div className="col-sm-3">
                        <input type="text" className="form-control" name="incubationPeriod" id="inputIncubationPeriod" defaultValue={newStrain.incubationPeriod} placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputGrowingPeriod" className="col-sm-1 col-form-label">Сколько дней плодоношения</label>
                    <div className="col-sm-3">
                        <input type="text" className="form-control" name="growingPeriod" id="inputGrowingPeriod" defaultValue={newStrain.growingPeriod} placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <label htmlFor="inputDescription" className="col-sm-1 col-form-label">Описание</label>
                    <div className="col-sm-3">
                        <input type="text" name="description" className="form-control" id="inputDescription" defaultValue={newStrain.description} placeholder="" onChange={this.handleInputChange} />
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-10">
                        <button type="button" className="btn btn-primary" onClick={this.handleCreate}>Сохранить</button>
                    </div>
                </div>
            </div>
        );
    }
}