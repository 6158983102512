import HttpApi, { BaseHttpResponse } from './HttpApi';
import { ChamberLong, ChamberShort, ChamberNew } from './types/chambers';
import { Pagination } from './types/pagination'
import { ChamberCounterValue } from './types/chamberReading';
export class ChamberApi extends HttpApi {
    async getAll() {
        return await this.get<Pagination<ChamberShort>, BaseHttpResponse>('/');
    }
    async getById(id: string) {
        return await this.get<ChamberLong, BaseHttpResponse>('/' + id);
    }
    async create(chamber: ChamberNew) {
        return await this.post<ChamberNew, BaseHttpResponse>('/', chamber);
    }
    async update(chamber: ChamberNew) {
        return await this.put<ChamberNew, BaseHttpResponse>('/', chamber);
    }
    async getPdf(id: string) {
        return await this.getBinary('/places/codes/' + id, { responseType: 'blob' });
    }

    async getCounter(chamberId: string) {
        return await this.get<ChamberCounterValue, BaseHttpResponse>('/counter/' + chamberId);
    }
    async setCounter(chamberId: string, value: number) {
        return await this.post<ChamberCounterValue, BaseHttpResponse>('/counter/' + chamberId, value);
    }

}
export default new ChamberApi('/api/chamber');